import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericApi } from '../api/api.generic';
import { StateService } from '../state.service';
import { OrganizationApi } from '../../shared/sdk/services/custom/Organization';
import { OrganizationService } from '../organization/organization.service';
import { Organization } from '../../shared/sdk/models/Organization';
import { take, switchMap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuardGuard implements CanActivate {
  constructor(
      private _organizationService: OrganizationService,
      private router: Router,
      private auth: AngularFireAuth,
      private _stateService: StateService<GenericApi>) {
}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.checkOrganization();
  }

  checkOrganization() {
  return this.auth.authState.pipe(
        take(1),
        switchMap(async (user: any) => {
          if (!user) {
            return(false);
          }
          // User loggedinUser
          const token = await user.getIdTokenResult();
          if (!token.claims) { // check claims
              //this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
              return false;
          }
          if(token.claims.role === 'admin'){
              return(true);

          }
          await this._organizationService.getOrganizations(user.uid).subscribe((organizations: Organization[]) => {
          if (organizations.length > 0) {
              // Si el usuario tiene organizaciones redirecciona hacia ellas
              this.router.navigate(['/organization/' + organizations[0].id + '/monitoring/']);
              return(false);

            } else {
              // Si no tiene deja pasar
              return(true);
            }
          });
     }));
 }
}

