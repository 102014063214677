<!-- ============================================================== -->
<!-- Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
<ng-container *ngIf="ready">
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <ul id="sidebarnav">
          <li class="user-profile">
            <a
              class="has-arrow waves-effect waves-dark"
              href="#"
              aria-expanded="false"
            >
              <img [src]="user.avatar | imagen" alt="user" />
              <span class="hide-menu">{{ user.displayName }}</span>
            </a>
            <ul aria-expanded="false" class="collapse">
              <li>
                <a routerLinkActive="active" routerLink="/perfil">Perfil</a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/account-settings">
                  Temas
                </a>
              </li>
              <li>
                <a (click)="_usuarioService.logout()" class="pointer">Logout</a>
              </li>
            </ul>
          </li>
          <li class="nav-devider"></li>
          <li class="nav-small-cap">PERSONAL</li>

          <ng-container *ngIf="userLogged">
            <li *ngFor="let menu of menuUser">
              <a
                class="has-arrow waves-effect waves-dark"
                aria-expanded="false"
              >
                <i [class]="menu.icono"></i>
                <span class="hide-menu">
                  {{ menu.titulo }}
                  <span class="label label-rouded label-themecolor pull-right">
                    {{ menu.submenu.length }}
                  </span>
                </span>
              </a>
              <ul aria-expanded="false" class="collapse">
                <li *ngIf="menu.titulo == 'Dashboards'" class="nav-title">
                  <a (click)="newDashboard()">
                    <i class="fa fa-plus-circle"></i>
                    Añadir Dashboard
                  </a>
                </li>

                <!-- <ng-container *ngIf="(_sidebar.menuData$() | async) as dataSidebar; else loading"> -->
                <ng-container *ngIf="_sidebar.dataSidebar">
                  <!-- <ng-container *ngIf="dataSidebar"> -->
                  <li *ngFor="let submenu of menu.submenu">
                    <a
                      routerLinkActive="active"
                      style="cursor: pointer;"
                      (click)="handlePath(submenu.url)"
                    >
                      {{ submenu.titulo }}
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    *ngFor="let dashboard of _sidebar.dataSidebar.dashboards"
                  >
                    <a
                      *ngIf="menu.titulo == 'Dashboards'"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="handlePath('/dashboard', dashboard.id)"
                    >
                      <i
                        class="fa {{ dashboard.icon ? dashboard.icon : null }}"
                      ></i>
                      {{ ' ' + dashboard.name }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>

          <!--  Admin menu -->
          <ng-container *ngIf="adminLogged">
            <li *ngFor="let menu of menuAdmin">
              <a
                class="has-arrow waves-effect waves-dark"
                aria-expanded="false"
              >
                <i [class]="menu.icono"></i>
                <span class="hide-menu">
                  {{ menu.titulo }}
                  <span class="label label-rouded label-themecolor pull-right">
                    {{ menu.submenu.length }}
                  </span>
                </span>
              </a>
              <ul aria-expanded="false" class="collapse">
                <li *ngIf="menu.titulo == 'Dashboards'" class="nav-title">
                  <a (click)="newDashboard()">
                    <i class="fa fa-plus-circle"></i>
                    Añadir Dashboard
                  </a>
                </li>

                <!-- <ng-container *ngIf="(_sidebar.menuData$() | async) as dataSidebar; else loading"> -->

                <ng-container *ngIf="_sidebar.dataSidebar">
                  <!-- <ng-container *ngIf="dataSidebar"> -->
                  <li *ngFor="let submenu of menu.submenu">
                    <a
                      routerLinkActive="active"
                      class="nav-link"
                      (click)="handlePath(submenu.url)"
                    >
                      {{ submenu.titulo }}
                    </a>
                  </li>
                  <li
                    class="nav-item"
                    *ngFor="let dashboard of _sidebar.dataSidebar.dashboards"
                  >
                    <a
                      *ngIf="menu.titulo == 'Dashboards'"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="handlePath('/dashboard', dashboard.id)"
                    >
                      <i
                        class="fa {{ dashboard.icon ? dashboard.icon : null }}"
                      ></i>
                      {{ ' ' + dashboard.name }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>

          <ng-container *ngIf="installLogged">
            <li *ngFor="let menu of menuInstalador">
              <a
                class="has-arrow waves-effect waves-dark"
                aria-expanded="false"
              >
                <i [class]="menu.icono"></i>
                <span class="hide-menu">
                  {{ menu.titulo }}
                  <span class="label label-rouded label-themecolor pull-right">
                    {{ menu.submenu.length }}
                  </span>
                </span>
              </a>
              <ul aria-expanded="false" class="collapse">
                <li *ngIf="menu.titulo == 'Dashboards'" class="nav-title">
                  <a (click)="newDashboard()">
                    <i class="fa fa-plus-circle"></i>

                    Añadir Dashboard
                  </a>
                </li>

                <!-- <ng-container *ngIf="(_sidebar.menuData$() | async) as dataSidebar; else loading"> -->

                <ng-container *ngIf="_sidebar.dataSidebar">
                  <li *ngFor="let submenu of menu.submenu">
                    <a
                      routerLinkActive="active"
                      (click)="handlePath(submenu.url)"
                    >
                      {{ submenu.titulo }}
                    </a>
                  </li>

                  <li
                    class="nav-item"
                    *ngFor="let dashboard of _sidebar.dataSidebar.dashboards"
                  >
                    <a
                      *ngIf="menu.titulo == 'Dashboards'"
                      class="nav-link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }"
                      (click)="handlePath('/dashboard', dashboard.id)"
                    >
                      <i
                        class="fa {{ dashboard.icon ? dashboard.icon : null }}"
                      ></i>
                      {{ ' ' + dashboard.name }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</ng-container>

<ng-template #loading>
  Loading menu...
</ng-template>

<!-- ============================================================== -->
<!-- End Left Sidebar - style you can find in sidebar.scss  -->
<!-- ============================================================== -->
