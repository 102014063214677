<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<section id="wrapper" class="login-register login-sidebar" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-box card-login">
        <div class="card-body" style="color: white">

            <form ngNativeValidate [formGroup]="forma" (ngSubmit)="registrarUsuario()" class="form-horizontal form-material" id="loginform" action="index.html">
                <a href="javascript:void(0)" class="text-center db"><img src="assets/images/logo-icon.png" alt="Home" /><br/><img src="assets/images/logo-text.png" alt="Home" /></a>
                <h3 class="box-title m-t-40 m-b-0">Registrate ahora</h3><small> Crea una cuenta y disfruta</small>
                <div class="form-group m-t-20">
                    <div class="col-xs-12">
                        <input style="color: white;padding-left: 20px;" formControlName="nombre" name="nombre" class="form-control" type="text" required placeholder="Nombre de Usuario">
                    </div>
                </div>
                <div class="form-group ">
                  <div class="col-xs-12">
                    <input style="color: white;padding-left: 20px;" formControlName="organizationName" name="organizationName" class="form-control" type="text" required placeholder="Nombre de Empresa">
                  </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input style="color: white;padding-left: 20px;" formControlName="correo" name="correo" class="form-control" type="email" required placeholder="Correo">
                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <input style="color: white;padding-left: 20px;" formControlName="password" name="password" class="form-control" type="password" required placeholder="Contraseña">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input style="color: white;padding-left: 20px;" formControlName="password2" name="password2" class="form-control" type="password" required placeholder="Confirma contraseña">
                    </div>
                </div>



                <div class="form-group" *ngIf="forma.errors?.sonIguales && !forma.pristine">
                    <p class="text-danger">
                        Las contraseñas deben de ser iguales
                    </p>
                </div>


                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="checkbox checkbox-primary p-t-0">
                            <input formControlName="condiciones" name="condiciones" id="checkbox-signup" type="checkbox">
                            <!-- <button type="button" class="btn btn-primary" (click)="openModal(template)">Open modal</button> -->

                            <label for="checkbox-signup" > Estoy de acuerdo con los <a style='color:red' (click)="openModal(template)">términos</a></label>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">Registrarme</button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">

                        <p>¿Tienes una cuenta? <a routerLink="/login" class="text-info m-l-5"><b>Ingresa ahora</b></a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- ============================================================== -->
<!-- End Wrapper -->
<!-- ============================================================== -->



<ng-template #template>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Politica de Privacidad y terminos</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>POL&Iacute;TICA DE PRIVACIDAD</strong></p>
        <p style="text-align: justify;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA S.L. se compromete a proteger la privacidad de los usuarios que accedan a esta web y/o cualquiera de sus servicios. La utilizaci&oacute;n de la web y/o cualquiera de los servicios ofrecidos por DESARROLLOS AGRICOLAS FEBESA S.L., implica la aceptaci&oacute;n por parte del usuario de las disposiciones contenidas en la presente Pol&iacute;tica de Privacidad y que sus datos personales sean tratados seg&uacute;n se estipula. Por favor, tenga en cuenta que, aunque pueda haber enlaces de nuestra web a otras webs, esta Pol&iacute;tica de Privacidad no se aplica a otras compa&ntilde;&iacute;as u organizaciones a las que la web est&eacute; redirigida. DESARROLLOS AGRICOLAS FEBESA S.L. no controla el contenido de las webs de terceros ni acepta ning&uacute;n tipo de responsabilidad por el contenido o las pol&iacute;ticas de privacidad de estos sitios web.</p>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><br><span lang="es-ES-u-co-trad"><strong><span style="background: #ffffff;">CUESTIONES SOBRE PRIVACIDAD</span></strong></span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">En cumplimiento del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, (RGPD), le ofrecemos la siguiente informaci&oacute;n sobre el tratamiento de los datos personales que usted pueda facilitarnos:</span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><span style="background: #ffffff;">-&nbsp;&iquest;Qui&eacute;n es el responsable del tratamiento de sus datos?</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA S.L.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">C.I.F.: B-30847560</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">Casas del Retiro, 25 &ndash; Los Dolores 30310 &ndash; Cartagena (Murcia) - Espa&ntilde;a</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Nuestros datos se encuentran en la parte superior de este aviso legal.&nbsp;</span></p>
        <ul>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Tratamos la informaci&oacute;n que se nos facilita para prestar y facturar nuestros servicios y productos.&nbsp;</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Si nos da su consentimiento tambi&eacute;n podemos tratar sus datos para enviarle informaci&oacute;n sobre productos, servicios o actividades.&nbsp;</span></p>
            </li>
        </ul>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><span style="background: #ffffff;">-&nbsp;&iquest;Durante cu&aacute;nto tiempo conservaremos sus datos?</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Los datos personales proporcionadas se conservar&aacute;n durante el tiempo estrictamente necesario. Es decir, mientras sea usuario de nuestros servicios o quiera seguir recibiendo informaci&oacute;n.&nbsp;</span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><br>&nbsp;</strong><strong><span style="background: #ffffff;">- &iquest;Qu&eacute; legitimaci&oacute;n tenemos para poder tratar sus datos?&nbsp;</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">La base legal para el tratamiento de sus datos son los consentimientos que nos d&eacute;.&nbsp;</span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">(Menores de 16 a&ntilde;os) Se entender&aacute; que la informaci&oacute;n enviada por menores de 16 a&ntilde;os lo ha sido por el consentimiento de sus representantes legales. De no ser as&iacute; el/la representante legal en cuanto sea posible.&nbsp;</span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><span style="background: #ffffff;">-&nbsp;&iquest;A qui&eacute;n comunicaremos sus datos?</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Los datos no ser&aacute;n comunicados a terceros, salvo que lo exija una ley o sea necesario para cumplir con la finalidad del tratamiento.&nbsp;</span></p>
        <ul>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Cualquier persona tiene derecho a obtener confirmaci&oacute;n sobre si estamos tratando o no sus datos personales.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Las personas interesadas tienen derecho a acceder a sus datos personales, as&iacute; como a solicitar la rectificaci&oacute;n de los datos inexactos o solicitar la supresi&oacute;n de los mismos cuando, entre otros motivos, los datos ya no sean necesarios para las finalidades para las cuales se recabaron.&nbsp;</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">En determinadas circunstancias les persones interesadas podr&aacute;n solicitar la limitaci&oacute;n del tratamiento de sus datos, en este caso &uacute;nicamente los conservaremos para el ejercicio o la defensa de reclamaciones.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">En determinadas circunstancias y por motivos relacionados con su situaci&oacute;n particular, el/la interesado/a podr&aacute; oponerse al tratamiento de sus datos. En este caso dejaremos de tratarlos, excepto por motivos leg&iacute;timos imperiosos o para el ejercicio o la defensa de posibles reclamaciones.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Las personas interesadas tambi&eacute;n tienen derecho a la portabilidad de sus datos.&nbsp;</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Finalmente, los/las interesados/as tienen derecho a presentar una reclamaci&oacute;n ante la Autoridad de Control competente.</span></p>
            </li>
        </ul>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><span style="background: #ffffff;">-&nbsp;&iquest;C&oacute;mo puede ejercer sus derechos?</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Envi&aacute;ndonos un escrito adjuntando una copia de un documento que lo identifique a nuestro domicilio o correo electr&oacute;nico (que aparecen al principio del presente Aviso Legal.</span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><span style="background: #ffffff;">-&nbsp;&iquest;C&oacute;mo hemos obtenido sus datos?</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Los datos personales que tratamos proceden del propio interesado, quien garantiza que los datos facilitados son ciertos y se hace responsable de comunicarnos cualquier modificaci&oacute;n de las mismas. Los datos que est&eacute;n marcados con un asterisco son obligatorios para poder darle el servicio solicitado.</span></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong><span style="background: #ffffff;">-&nbsp;&iquest;Qu&eacute; datos tratamos?</span></strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Los datos que tratamos oscilan entre las siguientes categor&iacute;as:</span></p>
        <ul>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Datos de car&aacute;cter identificativo.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Direcciones postales o electr&oacute;nicas.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Otros datos solicitados en nuestros formularios.</span></p>
            </li>
        </ul>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;"><br>&nbsp;Los datos son limitados, dado que &uacute;nicamente tratamos los datos necesarios para la prestaci&oacute;n de nuestros servicios y la gesti&oacute;n de nuestra actividad.&nbsp;</span></p>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad"><strong>&iquest;QU&Eacute; MEDIDAS DE SEGURIDAD APLICAMOS?</strong></span></p>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Aplicamos las medidas de seguridad establecidas en el art&iacute;culo 32 del RGPD, por lo tanto hemos adoptado las medidas de seguridad necesarias para garantizar un nivel de seguridad adecuado al riesgo del tratamiento de los datos que realizamos, con mecanismos que nos permiten garantizar la confidencialidad, integridad, disponibilidad y resiliencia permanente de los sistemas y servicios de tratamiento.</span></p>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Algunas de estas medidas son:</span></p>
        <ul>
            <li>
                <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Informaci&oacute;n de las pol&iacute;ticas de tratamiento de los datos al personal.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Realizaci&oacute;n de copias de seguridad peri&oacute;dicas.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Control de acceso a los datos.</span></p>
            </li>
            <li>
                <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">Procesos de verificaci&oacute;n, evaluaci&oacute;n y valoraci&oacute;n regulares.&nbsp;</span><br>&nbsp;&nbsp;</p>
            </li>
        </ul>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad"><strong>POL&Iacute;TICA DE COOKIES</strong></span></p>
        <p style="text-align: justify;margin-bottom: 0.35cm;line-height: 100%;background: rgb(255, 255, 255);"><span style="background: #ffffff;">En cumplimiento de la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSICE), se informa de los siguientes aspectos legales de la Web en el siguiente enlace a la&nbsp;</span><a href="https://iot.agrisensor.es/terms-cookies"><u>Pol&iacute;tica de Cookies</u></a><span style="background: #ffffff;">.</span></p>
        <p style="text-align: justify;margin-top: 0.53cm;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>POL&Iacute;TICA DE CALIDAD</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">El nombre comercial &ldquo;AGRISENSOR&rdquo; debe de ser, cada vez m&aacute;s, sin&oacute;nimo de calidad y servicio en electr&oacute;nica agr&iacute;cola y ganadera.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">Para conseguirlo, la Direcci&oacute;n de la empresa dotar&aacute; a la misma de los recursos humanos, t&eacute;cnicos y organizativos que le sean necesarios.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">Adem&aacute;s, dedicar&aacute; una especial atenci&oacute;n a la innovaci&oacute;n tecnol&oacute;gica, la motivaci&oacute;n de los empleados y la satisfacci&oacute;n de los clientes.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">A la innovaci&oacute;n tecnol&oacute;gica: adoptando una postura de b&uacute;squeda constante de la innovaci&oacute;n para crear productos nuevos o revitalizar los existentes y, as&iacute;, poder satisfacer siempre las necesidades de un mercado cambiante.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">A la motivaci&oacute;n de los empleados: para que participen activamente en los proyectos de calidad y mejora continua de la empresa, as&iacute; como en la consecuci&oacute;n de cada uno de los objetivos que en la misma se vayan marcando; procurando, al mismo tiempo, que en la empresa todos se encuentren a gusto.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">A la satisfacci&oacute;n de los clientes: identificando sus necesidades, satisfaci&eacute;ndolas con un costo apropiado y procurando superar siempre sus expectativas con relaci&oacute;n al producto suministrado, los servicios que lo acompa&ntilde;an y la empresa que lo fabrica: DESARROLLOS AGRICOLAS FEBESA, S.L. &nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA, S.L. &nbsp;se compromete a cumplir los requisitos de las otras partes interesadas y los requisitos reglamentarios y legales aplicables, y a mejorar continuamente el Sistema de Gesti&oacute;n de la Calidad.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">Esta pol&iacute;tica ser&aacute; revisada peri&oacute;dicamente para comprobar que contin&uacute;a siendo adecuada, y est&aacute; a disposici&oacute;n de las partes interesadas.</p>
        <p style="text-align: justify;background: transparent;margin-bottom: 0.28cm;line-height: 108%;"><br>&nbsp;</p>
        <p style="text-align: justify;margin: 0.53cm 0.1cm 0cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>CONDICIONES GENERALES DE VENTA</strong></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><br></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad"><strong>DISTRIBUCI&Oacute;N</strong></span></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">Como fabricantes de equipos para sensorizaci&oacute;n de riego, suministramos a trav&eacute;s nuestra y de empresas o profesionales que se dediquen a la instalaci&oacute;n de riego, previamente homologados por nosotros.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad"><strong>GARANT&Iacute;A</strong></span></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">Garantizamos los equipos que fabricamos por <strong>dos a&ntilde;os</strong>, a partir de la fecha de su venta, <strong>contra todo defecto de fabricaci&oacute;n.&nbsp;</strong></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad">Queda excluida de la garant&iacute;a la indemnizaci&oacute;n de da&ntilde;os directos e indirectos causados por la utilizaci&oacute;n de los equipos o accesorios fabricados o comercializados por nosotros.</span></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad">Quedar&aacute; exento de garant&iacute;a todo equipo manipulado o al que se haya realizado un cambio de las placas.</span></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">La garant&iacute;a cubre la reparaci&oacute;n, o sustituci&oacute;n si fuera preciso, del equipo averiado en nuestras instalaciones de Cartagena, a donde se deben remitir los equipos que precisen su intervenci&oacute;n.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">La garant&iacute;a no cubre los gastos de env&iacute;o de los equipos averiados.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><span lang="es-ES-u-co-trad"><strong>RECLAMACIONES Y DEVOLUCIONES</strong></span></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">No se admiten reclamaciones de material pasados 15 d&iacute;as desde la fecha de factura. No se admiten devoluciones de material pasado 1 a&ntilde;o de su venta. Los abonos solo se realizar&aacute;n por equipos u opciones de estos, que no hayan sido utilizados.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>REPARACIONES Y AMPLIACIONES</strong></p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">Los equipos para reparar o ampliar se deben enviar a nuestras instalaciones suficientemente protegidos.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">Si una vez evaluado el equipo enviado, los t&eacute;cnicos deciden que es Irreparable, se informar&aacute; al cliente y le ser&aacute; devuelto aprovechando el siguiente env&iacute;o de material.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">En ning&uacute;n caso vamos a guardar material de nuestros clientes durante m&aacute;s de 1 a&ntilde;o de su recepci&oacute;n. De no recibir noticias del remitente en este plazo, el material se va a desechar de la forma m&aacute;s oportuna.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">En las reparaciones, ofrecemos una garant&iacute;a de un a&ntilde;o, excepto en los equipos que tengan m&aacute;s de 5 a&ntilde;os de antig&uuml;edad, para los cuales se estudiar&aacute; cada caso.</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-left: 0.1cm;margin-right: 0.1cm;margin-bottom: 0cm;line-height: 100%;background: rgb(255, 255, 255);">Con la realizaci&oacute;n de un pedido o el env&iacute;o de cualquier material se aceptan todas las condiciones.</p>
        <p style="text-align: justify;background: transparent;margin-top: 0.53cm;margin-bottom: 0.79cm;line-height: 100%;"><br>&nbsp;</p>
      
        <p style="text-align: justify;margin-top: 0.53cm;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>AVISO LEGAL</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>1. INFORMACI&Oacute;N DEL TITULAR</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">En cumplimiento del art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y Comercio Electr&oacute;nico, a continuaci&oacute;n se exponen los datos identificativos del Titular:</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>Web</strong>: <u><a href="http://www.agrisensor.es/">www.agrisensor.es</a></u></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>Titular</strong>:&nbsp;DESARROLLOS AGRICOLAS FEBESA, S.L.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>Domicilio</strong>:&nbsp;Casas del Retiro, 25 &ndash; Los Dolores &ndash; Cartagena &ndash; 30310 (MURCIA) - Espa&ntilde;a</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>C.I.F</strong>: B-30847560</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>Tel&eacute;fono</strong>:&nbsp;968 51 48 52</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>Correo electr&oacute;nico</strong>: francisco.sanchez@grupofebesa.com</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>2. CONDICIONES DE ACCESO Y USO</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">Cualquier persona que acceda a la Web de AGRISENSOR tendr&aacute; la consideraci&oacute;n de Usuario. El Usuario se compromete a utilizar la p&aacute;gina Web y aquellos servicios puestos a su disposici&oacute;n a trav&eacute;s de la misma, de manera acorde a la Ley, moral, buenas costumbres y orden p&uacute;blico, as&iacute; como con lo dispuesto en el presente clausulado. En consecuencia, queda obligado a no utilizar la Web con fines o efectos il&iacute;citos y/o contrarios a lo establecido, lesivos de derechos y/o intereses de terceros o que, de cualquier forma, puedan da&ntilde;ar la Web o impedir su normal uso, o de los servicios accesibles a trav&eacute;s de la misma.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">La utilizaci&oacute;n de la Web y/o de sus Servicios, supondr&aacute; la aceptaci&oacute;n plena y sin reservas, y la validez, de todas y cada una de las cl&aacute;usulas recogidas en la &uacute;ltima versi&oacute;n actualizada de este Aviso Legal, por lo que el Usuario deber&aacute; ser consciente de la importancia de leerlas cada vez que visite la Web.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>3. PROPIEDAD INTELECTUAL</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">El c&oacute;digo fuente, los dise&ntilde;os gr&aacute;ficos, las im&aacute;genes, las fotograf&iacute;as, los v&iacute;deos, los sonidos, las animaciones, el software, los textos, as&iacute; como la informaci&oacute;n y los contenidos que se recogen en la Web est&aacute;n protegidos por la legislaci&oacute;n espa&ntilde;ola sobre los derechos de propiedad intelectual e industrial a favor de DESARROLLOS AGRICOLAS FEBESA, S.L. o de terceros licenciadores. No se permite la reproducci&oacute;n y/o publicaci&oacute;n, total o parcial, de la Web, ni su tratamiento inform&aacute;tico, su distribuci&oacute;n, su difusi&oacute;n, ni su modificaci&oacute;n, transformaci&oacute;n o descompilaci&oacute;n, ni dem&aacute;s derechos reconocidos legalmente a su titular, sin el permiso previo y por escrito del mismo. El Usuario, &uacute;nica y exclusivamente, puede utilizar el material que aparezca en la Web para su uso personal y privado, quedando prohibido su uso con fines comerciales o para incurrir en actividades il&iacute;citas.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">Todos los derechos derivados de la propiedad intelectual est&aacute;n expresamente reservados por DESARROLLOS AGRICOLAS FEBESA, S.L. &nbsp;o los terceros licenciadores, que velar&aacute; por el cumplimiento de las anteriores condiciones como por la debida utilizaci&oacute;n de los contenidos presentados en sus p&aacute;ginas web, ejercitando todas las acciones civiles y penales que le correspondan en el caso de infracci&oacute;n o incumplimiento de estos derechos por parte del Usuario.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;<strong>4. EXCLUSI&Oacute;N DE RESPONSABILIDAD</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA, S.L. &nbsp;act&uacute;a con la m&aacute;xima diligencia posible para que los datos y la informaci&oacute;n que ofrece en su sitio web est&eacute; actualizada en todo momento. No garantiza ni se hace responsable la exactitud y actualizaci&oacute;n de los contenidos del sitio web, reserv&aacute;ndose el derecho a modificar estos contenidos en cualquier momento.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA, S.L. &nbsp;tampoco ser&aacute; responsable de la informaci&oacute;n que se pueda obtener a trav&eacute;s de enlaces incluidos en el sitio web.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">Las relaciones comerciales entre clientes se regir&aacute;n por las condiciones generales que, si fuera necesario, se establecer&iacute;an por DESARROLLOS AGRICOLAS FEBESA, S.L. en un documento espec&iacute;fico a este efecto, o para los pactos concretos que se puedan acordar con los clientes.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>5. POL&Iacute;TICA DE INFORMACI&Oacute;N Y PUBLICIDAD COMERCIAL</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA, S.L. se compromete a trav&eacute;s de este medio a no realizar publicidad enga&ntilde;osa. A estos efectos, por lo tanto, no ser&aacute;n considerados como publicidad enga&ntilde;osa los errores formales o num&eacute;ricos que puedan encontrarse a lo largo del contenido de las distintas secciones de la Web, producidos como consecuencia de un mantenimiento y/o actualizaci&oacute;n incompleta de la informaci&oacute;n contenida en estas secciones. DESARROLLOS AGRICOLAS FEBESA, S.L. , como consecuencia de lo dispuesto en este apartado, se compromete a corregirlo tan pronto como tenga conocimiento de dichos errores.&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">DESARROLLOS AGRICOLAS FEBESA, S.L. se compromete a no remitir comunicaciones comerciales sin identificarlas como tales, conforme a lo dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y de comercio electr&oacute;nico. A estos efectos no ser&aacute; considerado como comunicaci&oacute;n comercial toda la informaci&oacute;n que se env&iacute;e a los clientes de DESARROLLOS AGRICOLAS FEBESA, S.L. siempre que tenga por finalidad el mantenimiento de la relaci&oacute;n contractual existente entre cliente y DESARROLLOS AGRICOLAS FEBESA, S.L., as&iacute; como el desempe&ntilde;o de las tareas de informaci&oacute;n y otras actividades propias del servicio que el cliente tiene contratado.</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">&nbsp;</p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);"><strong>6.&nbsp;CONDICIONES DE USO. JURISDICCI&Oacute;N Y LEYES APLICABLES</strong></p>
        <p style="text-align: justify;margin-bottom: 0.26cm;line-height: 100%;background: rgb(255, 255, 255);">El uso de este sitio web implica la aceptaci&oacute;n plena de los t&eacute;rminos del presente aviso legal. &nbsp;El presente Aviso Legal y todas las relaciones que se establezcan entre DESARROLLOS AGRICOLAS FEBESA, S.L. y el Usuario de la Web y sus servicios se regir&aacute;n por lo establecido en la legislaci&oacute;n espa&ntilde;ola.&nbsp;</p>
        <p style="margin-bottom: 0.28cm;line-height: 108%;text-align: left;background: transparent;"><br>&nbsp;</p>
    </div>
  </ng-template>
