import { Component, OnInit, OnDestroy } from '@angular/core';

import { SidebarService, UsuarioService } from '../../services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from 'src/app/services/state.service';
import { Organization } from '../sdk/models/Organization';
import { OrganizationApi } from '../sdk/services/custom/Organization';
import { Subscription } from 'rxjs/Subscription';
import { Dashboard } from '../sdk/models/Dashboard';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GenericApi } from 'src/app/services/api/api.generic';
import { User } from '../sdk/models/User';
import Swal from 'sweetalert2';
import { menuUser,menuAdmin,menuInstalador} from './menu';
import { thumbnail } from 'exifr';
// Import menu


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  public dataSidebar;

  // Menus
  public menuAdmin: any = menuAdmin;
  public menuUser: any = menuUser;
  public menuInstalador: any = menuInstalador;



  constructor(
    public _sidebar: SidebarService,
    public _usuarioService: UsuarioService,
    public _stateService: StateService<GenericApi>,
    public activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private organizationApi: OrganizationApi,
    private router: Router,

  ) { }

  user: User;
  id: string;
  userId: string;

  // Flags
  public isInitialized = false;
  public devicesReady = false;
  public messagesReady = false;
  public dataReady = false;
  public ready = false;

  // View
  public organization: Organization;
  paramsSubscription$: Subscription;


  // public menuUser  = menuUser;
  // public menuAdmin = menuAdmin;
  // public menuInstalador = menuInstalador;


  public userLogged  = false;
  public adminLogged = false;
  public installLogged = false;
  public roles: string;



  ngOnInit() {
    console.log('onInit Sidebar');
    this.userId = this._stateService.userState.usuario.loopbackId;
    this.user = this._stateService.userState.usuario;
    this.roles = this._stateService.userState.usuario.roles;

    this.refreshData();

    // setup
    this.setup();

    // set role
    this.setRoles();

    // data
    this.ready = true;

    // this._sidebar.cargarMenu();
  }


  refreshData() {
      // Check if organization view
      this.paramsSubscription$ = this.route.params.subscribe(params => {
        this.isInitialized = false;
        console.log('params full layout', params);
        if (params.id) {
          if(this.roles == 'admin'){
            //Bypass
            this.setup();
          }
          this.organizationApi.findById(params.id, {include: 'Members'}).subscribe((organization: Organization) => {
            this.organization = organization;
            this.setup();
          });
        } else {
          return;
        }
      });
  }







  setRoles() {
    switch (this.user.roles) {
      case 'admin':
          this.adminLogged = true;
          break;
      case 'user':
          this.userLogged = true;
          break;
      case 'install':
          this.installLogged = true;
          break;
    }
    console.log('roles',this.user.roles);
  }

  // Setup
  setup() {
  this._sidebar.menuData$(this.userId).subscribe((result: any) => {
    console.log(result);
    console.log('salida', this._sidebar.dataSidebar);

  });
}


/* Handle path of menu */
handlePath(url: string , id?: string) {

  // if admin()
  if (url.includes('admin')) {
    return this.router.navigateByUrl(`${url}`);
  }

  // organization
  if (this._stateService.organization) {
    const pathOrganization = `/organization/${this._stateService.api.id}`;
    console.log('pathOrganizationMenu', pathOrganization);
    if (!id) {
      // return this.router.navigate(['/organization/' + this._stateService.api.id]);
      return this.router.navigateByUrl(`${pathOrganization}${url}`);
    }
    // return this.router.navigate(['/organization/' + this._stateService.api.id + url + '/' + id]);
    return this.router.navigateByUrl(`${pathOrganization}${url}/${id}`);
  }

  // user
  if (!id) {
    return this.router.navigateByUrl(`${url}`);
  }
  return this.router.navigateByUrl(`${url}/${id}`);
}


ngOnDestroy() {
    console.log('Component sidebar Destroy');
    this.paramsSubscription$.unsubscribe();
  }


createDashboardExample() {

    Swal.fire({
      title: 'Dashboard',
      text: '¿Le gustaría crear un dashboard para este dispositivo?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, crear dashboard!'
    }).then((result) => {
      if (result.value) {
        const dashboard: Dashboard = new Dashboard();
        dashboard.name = 'Nuevo dashboard';

        if (this.organization) { dashboard.name = 'Dashboard compartido'; }


        try{
          this._stateService.api.createDashboards(this._stateService.api.id, dashboard).subscribe((dashboard: any) => {
            this._sidebar.dataSidebar.dashboards.push(dashboard);
            if (!this.organization) { this.router.navigate(['/dashboard/' + dashboard.id]); }
            else {
              this.router.navigate(['/organization/' + this.organization.id + '/dashboard/' + dashboard.id]); }
          });
        } catch(err) {
          Swal.fire(
            'Error!',
            'Erro al crear widget',
            'error'
          );
        }


      }
    });

  }


  newDashboard(): void {

    Swal.fire({
      title: 'Dashboard',
      text: '¿Esta seguro que desea crear un dashboard?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, crear dashboard!'
    }).then((result) => {
      if (result.value) {
        const dashboard: Dashboard = new Dashboard();
        dashboard.name = 'Nuevo dashboard';

        if (this.organization) { dashboard.name = 'Dashboard compartido'; }


        try{
          this._stateService.api.createDashboards(this._stateService.api.id, dashboard).subscribe((dashboard: any) => {
            this._sidebar.dataSidebar.dashboards.push(dashboard);
            if (!this.organization) { this.router.navigate(['/dashboard/' + dashboard.id]); }
            else {
              this.router.navigate(['/organization/' + this.organization.id + '/dashboard/' + dashboard.id]); }
          });
        } catch(err) {
          Swal.fire(
            'Error!',
            'Erro al crear widget',
            'error'
          );
        }


      }
    });


  }

  //   this.api.createDashboards(this.id, dashboard).subscribe(dashboard => {
  //     if (!this.organization) { this.router.navigate(['/dashboard/' + dashboard.id]); }
  //     else { this.router.navigate(['/organization/' + this.organization.id + '/dashboard/' + dashboard.id]); }
  //   });
  // }

}
