import { Injectable } from '@angular/core';
import { DeviceRegister } from '../../shared/sdk/models/DeviceRegister';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Device } from '../../shared/sdk/models/Device';

@Injectable({
  providedIn: 'root'
})
export class RegisterDeviceService {

  private api = `${environment.apiUrl}/${environment.apiVersion}`;
  constructor(private http: HttpClient) { }

  // Admin
  getRegisterDevice(from: number = 0) {

    const url = `${this.api}/DeviceRegisters`;
    const urlPaginada = `${this.api}/DeviceRegisters?desde=${from}`;

    return this.http.get<any>(url);

  }

  // Actuador mqtt

  createRegisterActuatorDevice(deviceRegister: DeviceRegister) 
  {
    const url = `${this.api}/DeviceRegisters`;
    return this.http.post(url, deviceRegister);
  }



  //  Sensor Sigfox
  createRegisterSensorDevice(deviceRegister: DeviceRegister) {
    const url = `${this.api}/DeviceRegisters`;
    return this.http.post(url, deviceRegister);
  }

  updateRegisterDevice(_id: string, deviceRegister: DeviceRegister){
    const url = `${this.api}/DeviceRegisters/${_id}`;
    return this.http.put(url, deviceRegister );

  }

  removeRegisterDevice(_id: string) {
    const url = `${this.api}/DeviceRegisters/${_id}`;
    return this.http.delete(url);

  }
}
