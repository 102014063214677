import { NgModule } from '@angular/core';
import { ImagenPipe } from './imagen.pipe';
import { ClickCopyDirective } from './click-copy.directive';



@NgModule({
  imports: [ ],
  declarations: [
    ImagenPipe,
    ClickCopyDirective
  ],
  exports: [
    ImagenPipe,
    ClickCopyDirective
  ]
})
export class PipesModule { }
