import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { FileItem } from '../../interfaces/file-item';
import { StateService } from '../state.service';
import { GenericApi } from '../api/api.generic';
import { MetaDefinition } from '@angular/platform-browser';
import exifr from 'exifr';


@Injectable({
  providedIn: 'root'
})
export class UploadImagesService {

  private FOLDER_IMAGES = 'img';
  constructor( private db: AngularFirestore, private _stateService: StateService<GenericApi>) { }



  private async saveImage( dataImg: object) {
    try {
      console.log('Ejecuto sentencia en firestore: ', dataImg);
      const consulta = await this.db.collection('img').add(dataImg);
      return consulta;
    } catch (e) {
      console.log(e);
      return (e);
    }
  }

  // private getExif(img) {
  //   let allMetaData;
  //   console.log(allMetaData);

  //   return allMetaData;
  // }


  async uploadImagesFirebase( imgs: FileItem[] | any) {
    const storageRef = firebase.storage().ref();
    let dataImg = {};
    
    const extract_data = async(item) => {
      try {
        // const metadata = this.getExif(item.archivo);
      
        // let output = await exifr.parse(item.archivo, ['ISO', 'Orientation', 'LensModel', 'DateTimeOriginal', 'Model']);
        // let {latitude, longitude} = await exifr.gps(item.archivo);

        const data = await exifr.parse(item.archivo);
        dataImg = {
          latitude: data.latitude,
          longitude: data.longitude,
          ISO: data.ISO,
          Orientation: data.Orientation,
          DateTimeOriginal: data.DateTimeOriginal,
          Make: data.Make,
          Model: data.Model,
        };
        console.log(data);
        return data;
      } catch (err) {
        console.log('No existe metadata', err);
        return false;
      }
    }

    const upload_firebase = (item) => {
      item.estaSubiendo = true;
    
      const uploadTask: firebase.storage.UploadTask =
      storageRef.child(`${ this.FOLDER_IMAGES }/${this._stateService.userState.usuario.id}/${ item.nombreArchivo }`)
      .put( item.archivo);

      uploadTask.on( firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) => item.progreso = (snapshot.bytesTransferred / snapshot.totalBytes) * 100 ,
        (error ) => console.error ('Error al subir', error),
        async () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('Imagen cargada correctamente');
            console.log('Ruta', downloadURL);
            item.estaSubiendo = false;
            dataImg['nombre'] = item.nombreArchivo,
            dataImg['url'] = downloadURL,
            dataImg['owner'] = this._stateService.userState.usuario.id;
            this.saveImage(dataImg);
          });
        });
    }

    if (Array.isArray(imgs) == false){
      const item = imgs
      console.log(item)
      extract_data(item)
      console.log('aqui')
      upload_firebase(item)

    } else{
      for ( const item of imgs ) {
        extract_data(imgs)
        if (item. progreso >= 100) {
          continue;
        }
        upload_firebase(item)
  
      }
    }
   

    console.log( imgs);
  }
}
