<!-- ============================================================== -->
<!-- Topbar header - style you can find in pages.scss -->
<!-- ============================================================== -->
<header class="topbar">
  <nav class="navbar top-navbar navbar-expand-md navbar-light">


    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <div class="navbar-header">
      <a class="navbar-brand" href="index.html">
        <!-- Logo icon --><b>
          <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
          <!-- Dark Logo icon -->
          <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
          <!-- Light Logo icon -->
          <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
        </b>
        <!--End Logo icon -->
        <!-- Logo text -->
        <span>
          <!-- dark Logo text -->
          <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />
          <!-- Light Logo text -->
          <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
        </span> </a>
    </div>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <div class="navbar-collapse">
      <!-- ============================================================== -->
      <!-- toggle and nav items -->
      <!-- ============================================================== -->
      <ul class="navbar-nav mr-auto">
        <!-- This is  -->
        <li class="nav-item"> <a class="nav-link nav-toggler hidden-md-up waves-effect waves-dark"
            href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
        <li class="nav-item"> <a class="nav-link sidebartoggler hidden-sm-down waves-effect waves-dark"
            href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
        <li class="nav-item hidden-sm-down"></li>
      </ul>
      <!-- ============================================================== -->
      <!-- User profile and search -->
      <!-- ============================================================== -->
      <ul class="navbar-nav my-lg-0">
        <!-- ============================================================== -->
        <!-- Search -->
        <!-- ============================================================== -->
        <li class="nav-item hidden-xs-down search-box"> <a class="nav-link hidden-sm-down waves-effect waves-dark"
            href="javascript:void(0)"><i class="ti-search"></i></a>
          <div class="app-search">
            <input #input (keyup.enter)="buscar( input.value )" type="text" class="form-control"
              placeholder="Buscar y enter"> <a class="srh-btn"><i class="ti-close"></i></a>
          </div>
        </li>

        <!-- ============================================================== -->
        <!-- Messages -->
        <!-- ============================================================== -->

        <li class="nav-item dropdown" (click)="countOrganizationsMembers($event)">
          <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" id="2" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-hospital-building"></i>
            <div class="notify">
              <span class="heartbit"></span>
              <span class="point"></span>
            </div>
          </a>
          <div class="dropdown-menu mailbox dropdown-menu-right animated fadeIn" aria-labelledby="2">
            <ul>
              <li>
                <div class="drop-title">Empresas</div>
              </li>
              <span *ngIf="countOrganizationsReady"
                class="badge badge-pill badge-danger">{{organizations.length}}</span>
              <li>
                <div class="message-center">
                  <!-- Organizaciones -->
                  <a *ngFor="let orga of organizations">
                    <div class="user-img">
                      <img src="{{orga.logo}}" alt="user" class="img-circle"
                        (click)="redirectToOrganizationView(orga.id)">
                      <span class="profile-status online pull-right"></span>
                    </div>
                    <div class="mail-contnet">
                      <h5 (click)="redirectToOrganizationView(orga.id)">{{orga.name}}</h5>
                      <span class="mail-desc">Numero de miembros : {{orga.countMembers}}</span>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <button href="#" class="dropdown-item" (click)="loadMoreOrganizations()">
                  <i class="fa fa-plus text-organization"></i> Cargar más
                </button>
              </li>
              <li>
                <button href="#" class="dropdown-item" (click)="createOrganization()">
                  <i class="fa fa-plus text-organization"></i> Nueva organizacion
                </button>
              </li>
            </ul>
          </div>
        </li>

        <!-- ============================================================== -->
        <!-- End Messages -->
        <!-- ============================================================== -->

        <!-- ============================================================== -->
        <!-- Organization -->
        <!-- ============================================================== -->
        <li *ngIf="organization" class="nav-item">

          <a href class="nav-link" [routerLink]="['/organization/', organization.id]">
            <!-- <img src="{{organization.logo}}" class="img-avatar"> -->
            <span class="d-md-down-none font-weight-bold hidden-md-down">{{organization.name}} </span>
          </a>
        </li>

        <!-- Edit orga -->
        <li *ngIf="organization" class="nav-item" style="cursor: pointer;" tooltip="Edit organization" placement="left">
          <!-- <a class="nav-link" (click)="openEditOrganizationModal()"> -->
          <a class="nav-link" [routerLink]="['/organization/', organization.id,'account-organization']">
            <i class="fa fa-pencil text-organization"></i>
          </a>
        </li>
        <!-- ============================================================== -->
        <!-- End Organization -->
        <!-- ============================================================== -->



        <!-- ============================================================== -->
        <!-- Language -->
        <!-- ============================================================== -->
        <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="flag-icon flag-icon-us"></i></a>
                    <div class="dropdown-menu dropdown-menu-right animated bounceInDown"> <a class="dropdown-item" href="#"><i class="flag-icon flag-icon-in"></i> India</a> <a class="dropdown-item" href="#"><i class="flag-icon flag-icon-fr"></i> French</a> <a class="dropdown-item" href="#"><i class="flag-icon flag-icon-cn"></i> China</a>                        <a class="dropdown-item" href="#"><i class="flag-icon flag-icon-de"></i> Dutch</a> </div>
                </li> -->
        <!-- ============================================================== -->
        <!-- Profile -->
        <!-- ============================================================== -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle waves-effect waves-dark" href="" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <img [src]="user.avatar | imagen" alt="user" class="profile-pic" />
          </a>
          <div class="dropdown-menu dropdown-menu-right animated fadeIn">
            <ul class="dropdown-user">
              <li>
                <div class="dw-user-box" align="center">
                  <div class="u-img"><img [src]="user.avatar | imagen" alt="user"></div>
                  <div class="u-text">
                    <h4> {{ user.displayName }} </h4>
                    <p class="text-muted"> {{ user.email }} </p>
                    <a routerLink="/perfil" class="btn btn-rounded btn-danger btn-sm">Ver perfil</a>
                  </div>
                </div>
              </li>
              <li role="separator" class="divider"></li>
              <li><a routerLink="/perfil"><i class="ti-user"></i> Perfil</a></li>
              <!-- <li><a href="#"><i class="ti-wallet"></i> My Balance</a></li>
                            <li><a href="#"><i class="ti-email"></i> Inbox</a></li> -->
              <li role="separator" class="divider"></li>
              <li><a routerink="/account-settings"><i class="ti-settings"></i> Temas aplicacion</a></li>
              <li role="separator" class="divider"></li>
              <li><a (click)="_usuarioService.logout()" class="pointer"><i class="fa fa-power-off"></i> Logout</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</header>
<!-- ============================================================== -->
<!-- End Topbar header -->
<!-- ============================================================== -->
