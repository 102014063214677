/* tslint:disable */
import {
  User,
  Alert,
  Connector
} from '../index';

declare var Object: any;
export interface ConditionalInterface {
  "active"?: boolean;
  "one_shot"?: boolean;
  "triggeredAt"?: Date;
  "message"?: string;
  "conditionalAlarm"?: any;
  "identifier"?: string;
  "id"?: any;
  "userId"?: any;
  "alertIds"?: Array<any>;
  "connectorId"?: any;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  user?: User;
  Alerts?: Alert[];
  Connector?: Connector;
}

export class Conditional implements ConditionalInterface {
  "active": boolean = true;
  "one_shot": boolean = false;
  "triggeredAt": Date = new Date(0);
  "message": string = '';
  "conditionalAlarm": any = <any>null;
  "identifier": string = '';
  "id": any = <any>null;
  "userId": any = <any>null;
  "alertIds": Array<any> = <any>[];
  "connectorId": any = <any>null;
  "createdAt": Date = new Date(0);
  "updatedAt": Date = new Date(0);
  user: User = null;
  Alerts: Alert[] = null;
  Connector: Connector = null;
  constructor(data?: ConditionalInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Conditional`.
   */
  public static getModelName() {
    return "Conditional";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Conditional for dynamic purposes.
  **/
  public static factory(data: ConditionalInterface): Conditional{
    return new Conditional(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Conditional',
      plural: 'Conditionals',
      path: 'Conditionals',
      idName: 'id',
      properties: {
        "active": {
          name: 'active',
          type: 'boolean',
          default: true
        },
        "one_shot": {
          name: 'one_shot',
          type: 'boolean',
          default: false
        },
        "triggeredAt": {
          name: 'triggeredAt',
          type: 'Date'
        },
        "message": {
          name: 'message',
          type: 'string'
        },
        "conditionalAlarm": {
          name: 'conditionalAlarm',
          type: 'any',
          default: <any>null
        },
        "identifier": {
          name: 'identifier',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'any'
        },
        "userId": {
          name: 'userId',
          type: 'any'
        },
        "alertIds": {
          name: 'alertIds',
          type: 'Array&lt;any&gt;',
          default: <any>[]
        },
        "connectorId": {
          name: 'connectorId',
          type: 'any'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        user: {
          name: 'user',
          type: 'User',
          model: 'User',
          relationType: 'belongsTo',
                  keyFrom: 'userId',
          keyTo: 'id'
        },
        Alerts: {
          name: 'Alerts',
          type: 'Alert[]',
          model: 'Alert',
          relationType: 'referencesMany',
                  keyFrom: 'alertIds',
          keyTo: 'id'
        },
        Connector: {
          name: 'Connector',
          type: 'Connector',
          model: 'Connector',
          relationType: 'belongsTo',
                  keyFrom: 'connectorId',
          keyTo: 'id'
        },
      }
    }
  }
}
