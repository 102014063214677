export const menuAdmin: any = [
  {
    titulo: "Dashboards",
    icono: "mdi mdi-gauge",
    submenu: [{ titulo: "Home", url: "/monitoring" }],
  },
  {
    titulo: "Parcelización",
    icono: "mdi mdi-terrain",
    submenu: [
      { titulo: "Home", url: "/monitoring/summary" },
      { titulo: "Mis Parcelas", url: "/monitoring/polygons" },
      { titulo: "Imagen Satelite", url: "/monitoring/satellite" },
      { titulo: "Datos de tiempo", url: "/monitoring/weather" },
      { titulo: "Explorer", url: "/monitoring/explorer" },
    ],
  },
  {
    titulo: "Información",
    icono: "mdi mdi-gauge",
    submenu: [
      { titulo: "Categorias", url: "/categories" },
      { titulo: "Alertas", url: "/alerts" },
      { titulo: "Dispositivos", url: "/devices" },
      { titulo: "Mensajes", url: "/messages" },
    ],
  },
  {
    titulo: "Desarrolladores",
    icono: "mdi mdi-folder-lock-open",
    submenu: [{ titulo: "Conectores", url: "/connectors" }],
  },
  {
    titulo: "Admin",
    icono: "mdi mdi-folder-lock-open",
    submenu: [
      { titulo: "Admin", url: "/admin" },
      { titulo: "Registrar Dispositivos", url: "/admin/show-register" },
      { titulo: "Parseadores", url: "/parsers" },
      { titulo: "Api", url: "/api" },
    ],
  },
 
];

export const menuUser: any = [
  {
    titulo: "Dashboards",
    icono: "mdi mdi-gauge",
    submenu: [{ titulo: "Home", url: "/monitoring" }],
  },
   {
    titulo: "Parcelización",
    icono: "mdi mdi-terrain",
    submenu: [
      { titulo: "Home", url: "/monitoring/summary" },
      { titulo: "Mis Parcelas", url: "/monitoring/polygons" },
      { titulo: "Imagen Satelite", url: "/monitoring/satellite" },
      { titulo: "Datos de tiempo", url: "/monitoring/weather" },
      { titulo: "Explorer", url: "/monitoring/explorer" },
    ],
  },
  {
    titulo: "Información",
    icono: "mdi mdi-gauge",
    submenu: [
      { titulo: "Alertas", url: "/alerts" },
      { titulo: "Dispositivos", url: "/devices" },
      { titulo: "Mensajes", url: "/messages" },
    ],
  },
  {
    titulo: "Desarrolladores",
    icono: "mdi mdi-folder-lock-open",
    submenu: [{ titulo: "Conectores", url: "/connectors" }],
  },
];

export const menuInstalador: any = [
  {
    titulo: "Dashboards",
    icono: "mdi mdi-gauge",
    submenu: [{ titulo: "Home", url: "/monitoring" }],
  },
  {
    titulo: "Parcelización",
    icono: "mdi mdi-terrain",
    submenu: [
      { titulo: "Home", url: "/monitoring/summary" },
      { titulo: "Mis Parcelas", url: "/monitoring/polygons" },
      { titulo: "Imagen Satelite", url: "/monitoring/satellite" },
      { titulo: "Datos de tiempo", url: "/monitoring/weather" },
      { titulo: "Explorer", url: "/monitoring/explorer" },
    ],
  },
  {
    titulo: "Información",
    icono: "mdi mdi-gauge",
    submenu: [
      { titulo: "Categorias", url: "/categories" },
      { titulo: "Alertas", url: "/alerts" },
      { titulo: "Dispositivos", url: "/devices" },
      { titulo: "Mensajes", url: "/messages" },
    ],
  },
  {
    titulo: "Desarrolladores",
    icono: "mdi mdi-folder-lock-open",
    submenu: [{ titulo: "Conectores", url: "/connectors" }],
  },
  {
    titulo: "Admin",
    icono: "mdi mdi-folder-lock-open",
    submenu: [
      { titulo: "Admin", url: "/admin" },
      { titulo: "Registrar Dispositivos", url: "/admin/show-register" },
      { titulo: "Parseadores", url: "/parsers" },
      { titulo: "Api", url: "/api" },
    ],
  },
];
