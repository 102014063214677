/* tslint:disable */

declare var Object: any;

export interface IOutputActuator{
  output: string
  status: boolean,
}

export interface AlertActuatorInterface {
  "state": Array<IOutputActuator>;
  "actuatorId": string;
  "id"?: number;
}

export class AlertActuator implements AlertActuatorInterface {
  "actuatorId": string = '';
  "state": Array<any> = <any>[];
  "id": number = 0;
  constructor(data?: AlertActuatorInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `AlertActuator`.
   */
  public static getModelName() {
    return "AlertActuator";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of AlertActuator for dynamic purposes.
  **/
  public static factory(data: AlertActuatorInterface): AlertActuator{
    return new AlertActuator(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'AlertActuator',
      plural: 'AlertActuators',
      path: 'AlertActuators',
      idName: 'id',
      properties: {
        "state": {
          name: 'status',
          type: 'Array&lt;any&gt;'
        },
        "actuatorId": {
          name: 'actuatorId',
          type: 'string'
        },
        "id": {
          name: 'id',
          type: 'number'
        },
      },
      relations: {
      }
    }
  }
}
