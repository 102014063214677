import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { UserApi } from '../../shared/sdk/services/custom/User';
import { StateService, IUserState } from '../state.service';
import { GenericApi } from '../api/api.generic';
import { AngularFireAuth} from '@angular/fire/auth';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { UsuarioService } from '../usuario/usuario.service';
import { switchMap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { Organization } from '../../shared/sdk/models/Organization';
import { OrganizationService } from '../organization/organization.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private userApi: UserApi, private router: Router,
              private auth: AngularFireAuth,
              private _usuarioService: UsuarioService,
              private _stateService: StateService<GenericApi>,
              private _organizationService: OrganizationService
              ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authState.pipe(
      take(1),
      switchMap(async (user: any) => {
        if (!user) {
          this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
          return false;
        }
          // User loggedinUser
        const token = await user.getIdTokenResult();
        if (!token.claims) { // check claims
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            return false;
        } else {
            // OK
            // SOlo el admin pasa
            if (token.claims.role === 'admin') {
              console.log('Seteo Api por cada ruta: UserApi');
              console.log('Guard auth. Pre Data User: ', user.uid);
              // Importante
              this._stateService.organization = false;
              this._stateService.userState.organization = false;
              this._stateService.userState.tokenFirebase = token.token;

              this._stateService.setApi(this.userApi, user.uid);

              //
              const userData = {
                    ...this._stateService.userState.usuario,
                    id: user.uid,
                    loopbackId: user.uid,
                    roles: token.claims.role
                  };
              this._stateService.userState.usuario = userData;
              console.log('Guard auth. Post Data User: ', this._stateService.userState.usuario);
              return true;
            } else {

              // Si el usuario tiene organizaciones asignadas y no es admin solo puede entrar a la parte de Organizacion
              await this._organizationService.getOrganizations(user.uid).subscribe((organizations: Organization[]) => {
                if (organizations.length > 0) {
                    // Si el usuario tiene organizaciones redirecciona hacia ellas
                    this.router.navigate(['/organization/' + organizations[0].id + '/monitoring/']);
                    return(false);
                } else {
                  // Aqui nunca debería de llegar pq todos los usuarios tienen asignadas minimo una organizacion
                  console.log('Error el usuario no tiene organizaciones asignadas');
                  return(false);
                  }
                });
              }
        }
      }),
          catchError((error) => {
                  console.log('Error en el guard:', error);
                  this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
                  return of(false);
                  // return Observable.throw(error || 'Server Error');
                })
              );
            }
}
