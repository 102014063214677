import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import * as _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { UserApi } from '../../shared/sdk/services/custom/User';
import { Role } from '../../shared/sdk/models/Role';
import { take, switchMap, catchError } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { StateService } from '../state.service';
import { GenericApi } from '../api/api.generic';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private userApi: UserApi, private router: Router,
              private auth: AngularFireAuth,
              private _stateService: StateService<GenericApi>,
              ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAdmin(state);
  }

  checkAdmin(state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authState.pipe(
      take(1),
      switchMap(async (user: any) => {
        if (!user) {
          this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
          return false;
        }
          // User loggedinUser
        const token = await user.getIdTokenResult();
        if (!token.claims) { // check claims
            this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
            return false;
        } else {
            // OK
            if (token.claims.role === 'admin') {
              this._stateService.setApi(this.userApi, user.uid);
              const userData = {
                ...this._stateService.userState.usuario,
                id: user.uid,
                loopbackId: user.uid,
                roles: token.claims.role
              };
              this._stateService.userState.usuario = userData;
              console.log('Guard auth. Post Data User: ', this._stateService.userState.usuario);
              return true;
            } else {
              return false;
            }
          }
      })
    );
  }
}


    // catchError((err: any) => {
    //   console.log('Error en el guard:', error);
    //   this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
    //   return of(false);
    //   // return Observable.throw(error || 'Server Error');
    // })


