import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { UsuarioService } from '../usuario/usuario.service';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/internal/operators/take';
import { switchMap } from 'rxjs/operators';
import { GenericApi } from '../api/api.generic';
import { StateService } from '../state.service';
import { UserApi } from 'src/app/shared/sdk/services/custom/User';

@Injectable()
export class VerificaTokenGuard implements CanActivate {

  constructor(
    public _usuarioService: UsuarioService,
    public router: Router,
    private auth: AngularFireAuth,
    private _stateService: StateService<GenericApi>,
    private userApi: UserApi
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.authState.pipe(
      take(1),
      switchMap(async (user: any) => {
        if (!user) {
          this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
          return false;
        }

        // User loggedinUser
        const token = await user.getIdTokenResult();
        if (!token.claims) { // check claims
              this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
              return false;
        } else {
          // OK
          console.log('**VerificaTokenGUard');
          // Importante

          const userData = {
             ...this._stateService.userState.usuario,
             id: user.uid,
             loopbackId: user.uid,
             roles: token.claims.role
          };
          this._stateService.userState.tokenFirebase = token.token;
          this._stateService.userState.usuario = userData;
          console.log('Usuario _state: ', this._stateService.userState.usuario);


          //
          return true;
        }
      }));
    }

  }


  // verificaRenueva( fechaExp: number ): Promise<boolean>  {

  //   return new Promise( (resolve, reject) => {

  //     const tokenExp = new Date( fechaExp * 1000 );
  //     const ahora = new Date();

  //     ahora.setTime( ahora.getTime() + ( 1 * 60 * 60 * 1000 ) );

  //     // console.log( tokenExp );
  //     // console.log( ahora );

  //     if ( tokenExp.getTime() > ahora.getTime() ) {
  //       resolve(true);
  //     } else {

  //       // this._usuarioService.renuevaToken()
  //       //       .subscribe( () => {
  //       //         resolve(true);
  //       //       }, () => {
  //       //         this.router.navigate(['/login']);
  //       //         reject(false);
  //       //       });

  //     }

  //   });

  // }


  // expirado( fechaExp: number ) {

  //   const ahora = new Date().getTime() / 1000;

  //   if ( fechaExp < ahora ) {
  //     return true;
  //   } else {
  //     return false;
  //   }


  // }



// }
