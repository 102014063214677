import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalUploadService } from '../components/modal-upload/modal-upload.service';
import {
  SettingsService,
  SidebarService,
  SharedService,
  UsuarioService,
  AdminGuard,
  OrganizationGuard,
  AuthGuard,
  DashboardGuard,
  SubirArchivoService,
  VerificaTokenGuard,
 } from './service.index';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    UsuarioService,
    SettingsService,
    SidebarService,
    SharedService,
    AdminGuard,
    AuthGuard,
    DashboardGuard,
    SubirArchivoService,
    ModalUploadService,
    VerificaTokenGuard,
    OrganizationGuard
  ],
  declarations: []
})
export class ServiceModule { }
