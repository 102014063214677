import { Dashboard } from '../../shared/sdk/models/Dashboard';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { of } from 'rxjs/internal/observable/of';
import { UserApi } from '../../shared/sdk/services/custom/User';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/internal/Observable';
import { StateService } from 'src/app/services/state.service';
import { GenericApi } from 'src/app/services/api/api.generic';

@Injectable()
export class DashboardGuard implements CanActivate {

  constructor(private userApi: UserApi, private router: Router, private _stateService: StateService<GenericApi>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('guard dahboard');
    return this.checkDashboardOwner(route);
  }

  checkDashboardOwner(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userApi.findByIdDashboards(this._stateService.userState.usuario.id, route.params.id)
    .map((dashboards: Dashboard[]) => {
      if (dashboards) { return true; } else {
        console.log('Bloqueado por Guard de Dashboard');
        // Not dashboard owner so redirect to overview page
        this.router.navigate(['/']);
        return false;
      }
    }).catch(res => {
      // Not dashboard owner so redirect to overview page
      this.router.navigate(['/']);
      return of(false);
    });
  }
}
