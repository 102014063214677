import { Component, OnInit, NgZone, OnDestroy, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { UsuarioService, OrganizationService } from '../services/service.index';
import { Usuario } from '../models/usuario.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Organization } from 'src/app/shared/sdk/models/Organization';
import { from } from 'rxjs/internal/observable/from';
import { map } from 'rxjs/internal/operators/map';
import { Subscription } from 'rxjs/Subscription';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

declare function init_plugins();

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./login.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  register$: Subscription;
  modalRef: BsModalRef;

  forma: FormGroup;

  constructor(
    private modalService: BsModalService,
    public _organizationService: OrganizationService,
    public _usuarioService: UsuarioService,
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) { }


  sonIguales( campo1: string, campo2: string ) {

    return ( group: FormGroup ) => {

      const pass1 = group.controls[campo1].value;
      const pass2 = group.controls[campo2].value;

      if ( pass1 === pass2 ) {
        return null;
      }

      return {
        sonIguales: true
      };

    };

  }



  ngOnInit() {
      init_plugins();

      this.forma = new FormGroup({
        nombre: new FormControl( null , Validators.required ),
        correo: new FormControl( null , [Validators.required, Validators.email] ),
        password: new FormControl( null , Validators.required ),
        password2: new FormControl( null , Validators.required ),
        organizationName: new FormControl( null , Validators.required ),
        condiciones: new FormControl( false )
      }, { validators: this.sonIguales( 'password', 'password2' )  } );


      // this.forma.setValue({
      //   nombre: 'Test ',
      //   correo: 'test@test.com',
      //   password: '123456',
      //   password2: '123456',
      //   condiciones: true
      // });

  }



  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  registrarUsuario() {

    if ( this.forma.invalid ) {
      return;
    }

    if ( !this.forma.value.condiciones ) {
      Swal.fire('Importante', 'Debe de aceptar las condiciones', 'warning');
      return;
    }
    if ( this.forma.value.password.length < 5 ) {
      Swal.fire('Importante', 'La contraseña debe ser de un minimo de 6 caracteres', 'warning');
      return;
    }
    const usuario = new Usuario(
      this.forma.value.correo,
      this.forma.value.password,
      this.forma.value.nombre,
      this.forma.value.organizationName,

    );
    // registra en firebase y en loopback

    const organizationToAddOrEdit = new Organization();
    // const answers = JSON.stringify(result.value);
    organizationToAddOrEdit.name = this.forma.value.organizationName;

    this.register$ = this._usuarioService.registerUserTest(usuario).pipe(
      switchMap((login) => from(this._usuarioService.loginFirebase(usuario))),
      map((user) => {
        organizationToAddOrEdit.userId = user.user.uid;
        return user;
      }),
      switchMap((user) =>
        this._organizationService.createOrganization(user.user.uid, organizationToAddOrEdit)),
      catchError( err => {
        Swal.fire('error', err.error, 'error');
        return of(err);
      }),
    ).subscribe(data => {
      console.log(data);
      this.ngZone.run(() => {
        // this.router.navigate(['overview']);
        this.router.navigate(['organization/' + data.id]);
      });

    });
    }

    ngOnDestroy(): void {
      this.register$.unsubscribe();
    }
}
    // try {
    //   const dataUser = await this._usuarioService.registerUserTest(usuario).toPromise();
    //   console.log(dataUser);
    //   Swal.fire('ok', 'Usuario creado', 'error');
    //   // register user

    //   // Check claims
    // } catch (err) {
    //   console.log(err);
    //   // revisar codigo de errores
    //   // https://firebase.google.com/docs/auth/admin/errors
    //   Swal.fire(err.code, err.message, 'error');
    //   return throwError( err.code );
    // }




  //               .subscribe((user: Usuario) => {
  //                 console.log(user, 'devuelve el user Loopback');
  //                 console.log(user);
  //                 // Login
  //                 this._usuarioService.login(user);
  //                 this.router.navigate(['/login']);
  //               }, err => {
  //                 // console.log(err);
  //                 if (err.statusCode === 422) {
  //                   const errorMessage = 'Correo electrónico ya registrado';
  //                   Swal.fire( err.error.mensaje, errorMessage, 'error' );
  //                   return throwError( err.statusCode );
  //                 } else {
  //                   const errorMessage = 'Invalido usuario o password.';
  //                   Swal.fire( err.error.mensaje, errorMessage, 'error' );

  //                   return throwError( err.statusCode );
  //                 }
  //               });

  // }
