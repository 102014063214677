import { Component, OnInit } from '@angular/core';
import { UsuarioService, OrganizationService } from '../../services/service.index';
import { Usuario } from '../../models/usuario.model';
import { Router, ActivatedRoute } from '@angular/router';
import { StateService } from '../../services/state.service';
import { GenericApi } from 'src/app/services/api/api.generic';
import { User } from '../sdk/models/User';

import Swal from 'sweetalert2';
import { Organization } from 'src/app/shared/sdk/models/Organization';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  usuario: Usuario;
  user: User;
  private userId: string;
  public role: string;
  public countOrganizationsReady;
  public organizations: Organization[] = [];
  public organization: any;
  private page = 0;
  private limit = 10;

  private organizationToAddOrEdit: Organization = new Organization();
  public isInitialized = false;

  constructor(
    public _usuarioService: UsuarioService,
    public _stateService: StateService<GenericApi>,
    public _organizationService: OrganizationService,
    public router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    console.log('ngOnInit header');
    this.organization = this._stateService.userState.organization;
    this.user = this._stateService.userState.usuario;
    this.userId = this._stateService.userState.usuario.loopbackId;
    this.role = this._stateService.userState.usuario.roles;
    console.log('Role del usuario;', this.role);
    this.refreshData();
  }

  /* Si cambiamos entre organizaciones forzar la recarga de datos */
  refreshData() {
    // Check if organization view
    this.route.params.subscribe(parentParams => {
      if (parentParams.id) {
        const organizationId = parentParams.id;
        this.organization = this._stateService.userState.organization;
        this.setup();
      } else {
        this.setup();
      }
    });
  }

  setup() {
    this.page = 0;
    this.organizations = [];
    this.loadMoreOrganizations();
  }

  loadMoreOrganizations() {
    this._organizationService.getOrganizations(this.userId, this.page, this.limit).subscribe((organizations: Organization[]) => {
      this.organizations = this.organizations.concat(organizations);
      this.page++;
    });
  }

  buscar(termino: string) {
    this.router.navigate(['/busqueda', termino]);
  }

  redirectToOrganizationView(orgId: string): void {
    if (
      this.route.snapshot.firstChild.routeConfig.path === 'categories'
      || this.route.snapshot.firstChild.routeConfig.path === 'alerts'
      || this.route.snapshot.firstChild.routeConfig.path === 'devices'
      || this.route.snapshot.firstChild.routeConfig.path === 'messages'
      || this.route.snapshot.firstChild.routeConfig.path === 'connectors'
      || this.route.snapshot.firstChild.routeConfig.path === 'account-organization'
    ) {
      this.router.navigate(['organization/' + orgId + '/' + this.route.snapshot.firstChild.routeConfig.path]);
    } else {
      this.router.navigate(['organization/' + orgId]);
    }
  }

  countOrganizationsMembers(open: boolean): void {
    if (open) {
      // Count organization members
      this.organizations.forEach((organization: any) => {
        this._organizationService.countMembers(organization.id).subscribe(result => {
          organization.countMembers = result.count;
        });
      });
    }
  }

  createOrganization() {
    Swal.mixin({
      input: 'text',
      confirmButtonText: 'Next &rarr;',
      showCancelButton: true,
      progressSteps: ['1']
    }).queue([
      {
        title: 'Indique Nombre Organizacion',
        text: ''
      },
    ]).then((result) => {
      if (result.value) {
        this.organizationToAddOrEdit = new Organization();
        this.organizationToAddOrEdit.name = result.value[0];
        this.organizationToAddOrEdit.userId = this.userId;

        this._organizationService.createOrganization(this.userId, this.organizationToAddOrEdit)
          .subscribe((organizationTemp: Organization) => {
            console.log('Organization created', organizationTemp);
            this.router.navigate(['organization/' + organizationTemp.id]);
            this._organizationService.findById(organizationTemp.id).subscribe((organizationTemp2: Organization) => {
              this.organization = organizationTemp2;
            });
          });
        Swal.fire({
          title: 'Realizado!',
          html: `
            <pre><code>Organizacion creada</code></pre>
          `,
          confirmButtonText: 'Ok!'
        });
      }
    });
  }
}
