import { Injectable, NgZone } from '@angular/core';
import { Usuario } from '../../models/usuario.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';



import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { Router } from '@angular/router';
import { SubirArchivoService } from '../subir-archivo/subir-archivo.service';
import Swal from 'sweetalert2';

import { StateService, IUserState, UserState } from '../state.service';
import { GenericApi } from '../api/api.generic';


import { User } from '../../shared/sdk/models/User';
// import { User } from 'firebase';
// import { User } from "../services/user";
// import { User } from 'firebase';


import { AngularFireAuth } from '@angular/fire/auth';

import { map, first} from 'rxjs/operators';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import * as firebase from 'firebase/app';

// https://www.positronx.io/full-angular-7-firebase-authentication-system/
// https://stackoverflow.com/questions/58941738/onauthstatechanged-in-firebase-or-firebase-auth-does-not-return-values


@Injectable()
export class UsuarioService {

  token: string;
  menu: any[] = [];
  verifyPassword = '';



  constructor(
    private afAuth: AngularFireAuth,
    public afs: AngularFirestore,   // Inject Firestore service
    public _subirArchivoService: SubirArchivoService,
    public http: HttpClient,
    public router: Router,
    public _stateService: StateService<GenericApi>,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {

    // this.user$ = this.afAuth.authState;

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */

  }



  // getUser() {
  //   return this.user$.pipe(first());
  // }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  // SetUserData(user) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: UserCustom = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   };
  //   return userRef.set(userData, {
  //     merge: true
  //   });
  // }

  /**
   * Initiate the password reset process for this user
   * @param email email of the user
   */
  // https://medium.com/@c_innovative/implementing-password-reset-can-be-a-tricky-but-inevitable-task-737badfb7bab
  // https://codinglatte.com/posts/angular/handling-firebase-password-resets-in-angular/

  sendPasswordResetEmail(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }




  logout() {
    this.clearLocalStorage(); // Optional to clear localStorage
    this._stateService = null;
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  loginGoogle( token: string ) {

  }




  getUser(): User {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? user : false;
  }






   // Auth logic to run auth providers
   AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
       this.ngZone.run(() => {
          this.router.navigate(['overview']);
        });
       // this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error);
    });
  }



  // getTokenUserFirebase( usuario: Usuario) {
  //   this.auth.onAuthStateChanged((user) =>  {
  //     if (user) {
  //       console.log(user); // It shows the Firebase user
  //       console.log(this.auth.user); // It is still undefined
  //       user.getIdToken().then((idToken) => {  // <------ Check this line
  //          console.log(idToken); // It shows the Firebase token now
  //          this.setClaims(idToken);
  //       });
  //     }
  //   });
  // }



  async reAuthenticate() {
    await (await this.afAuth.currentUser).getIdToken(true);
  }


  // Set data on localStorage
  setUserLoggedIn(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
    console.log('saved on localStorage');
  }
  // get data on localStorage
  getUserLoggedIn() {
    if (localStorage.getItem('user')) {
      JSON.parse(localStorage.getItem('user'));
    } else {
      console.log('localStorage empty');
    }
  }
  // Optional: clear localStorage
  clearLocalStorage() {
    localStorage.clear();
  }

   /*
   * login with google
   */
  loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    this.afAuth.signInWithPopup(provider)
      .then(data => {
        this.ngZone.run(() => this.router.navigate(['dashboard'])).then();
      })
      .catch(error => {
        console.log(error);
      });
    // setCustomParameters host domain (hd)
    /*
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('email');
        provider.setCustomParameters({
          'hd':'domain.edu.mx'
        });
        this.afAuth.auth.signInWithPopup(provider)
        .then((data)=>{
          this.router.navigate(['/dashboard']);
        })
        .catch((error)=>{
          console.log(error)
        });
      */
  }


  loginFirebase(usuario: Usuario): Promise<firebase.auth.UserCredential> {
    return new Promise(async (resolve: any , reject: any) => {
      try {
        console.log('Login Usuario Firebase');
        const userFirebase = await this.afAuth.signInWithEmailAndPassword(usuario.email, usuario.password);
        resolve(userFirebase);
      } catch (error) {
        reject(error);
      }
    });
  }

  loginLoopback(usuario: Usuario, tokenFirebase: string) {
    console.log('Login Usuario Loopback');
    const url = environment.apiUrl  + '/' + environment.apiVersion + '/users/login';

    const headers = new HttpHeaders()
    .set('skip', 'true');


    // let _method: string = "POST";
    // let _url: string = LoopBackConfig.getPath() + "/" + LoopBackConfig.getApiVersion() +
    // "/users/login";
    // let _routeParams: any = {};
    // let _postBody: any = {
    //   credentials: credentials
    // };
    // let _urlParams: any = {};
    // if (typeof include !== 'undefined' && include !== null) _urlParams.include = include;
    // let result = this.request(_method, _url, _routeParams, _urlParams, _postBody, null, customHeaders)
    //   .pipe(
    //     map(
    //     (response: any) => {
    //       response.ttl = parseInt(response.ttl);
    //       response.rememberMe = rememberMe;
    //       this.auth.setToken(response);
    //       return response;
    //     }
    //   )
    //   );
    //   return result;


    return this.http.post(url, usuario, {headers}).pipe(
          map(
          (response: any) => {
            console.log('responseLoopbacklogin', response);

            // response.ttl = parseInt(response.ttl);
            // response.rememberMe = rememberMe;
            // this.auth.setToken(response);
            return response;
          }
        )
    );
  }



  // setClaims(data: any) {
  //   let url = URL_SERVICIOS + '/users/setClaimsFirebase';
  //   url += '?access_token=' + data.tokenFirebase; // set token in params


  //   console.log('data a enviar', data);

  //   return this.http.post(url, data).map((resp: any) => {
  //     // Asigno el usuario final al servicio de Usuario


  //     console.log(resp);
  //     // if(resp.){
  //     //   // update
  //     // } else(){
  //     //   //error
  //     // }
  //     });
  // }


  async getTokenFirebase() {
    const token = (await this.afAuth.currentUser).getIdToken();
    return token;
  }

  // setLocalStorage(user: User, claims: object) {
  //   console.log(claims);
  //   console.log(user);
  //   this._stateService.userState.usuario.loopbackId =  claims['loopbackId'];

  //   this._stateService.userState.role = claims['role'];
  //   this._stateService.userState.user = user;

  // }


  registerUser(usuarioIn: Usuario) {
    // Check values
    // if (this.usuario.password !== this.verifyPassword) {
    //   const errorMessage = 'Passwords no coinciden';
    //   Swal.fire( errorMessage, 'error' );
    //   return throwError( errorMessage);
    // }

    // usuario.email = usuario.email.toLocaleLowerCase();
    // console.log(usuario);
    // // this.usuario.id = null;
    // // this.usuario.createdAt = new Date();
    // const user = {
    //   email: usuario.email,
    //   password: usuario.password
    // };
    return new Promise(async (resolve: any , reject: any) => {
      console.log(usuarioIn);
      try {

        console.log(usuarioIn);
        // Primero registro firebase para que el backend pueda tenerlo registrado y pueda setear el role
        const usuarioFirebase = {
          email: usuarioIn.email,
          password: usuarioIn.password,
          nombre: usuarioIn.nombre,
        };

        this.registerUserTest(usuarioFirebase);

        const registerFirebase = await this.afAuth.createUserWithEmailAndPassword(usuarioIn.email, usuarioIn.password);   // register
        console.log('Usuario firebase registrado ', registerFirebase);
        const registerLoopback = await this.registerUserLoopback(usuarioIn).toPromise();   // register
        console.log('usuario Loopback registrado', registerLoopback);

        //this._stateService.userState.usuario = new UserCustom();

        const user = await this.afAuth.signInWithEmailAndPassword(usuarioIn.email, usuarioIn.password);
        // console.log(dataRegister);
        //
        // console.log('usuario firebase logueado', user);
        // const userFirebase = await this.getUser().toPromise();
        // console.log('Token firebase actualizado claims', userFirebase);
        // const user3 = userFirebase.getIdTokenResult(true);
        // console.log('Usuario firebase actualizado claims', user3);
        // const claims = (await (await user3).claims);

        // console.log('loopbackId', loopbackId);
        // console.log('role', role);

        // this.setLocalStorage(userFirebase, claims);


        this.ngZone.run(() => {
          this.router.navigate(['overview']);
        });
        resolve(true);
      } catch (err) {
        console.log('Error al registrar', err);
        reject(err);
      }
    });
  }

  // getCurrentUser() {
  //   return new Promise<any>((resolve, reject) => {
  //     const user = this.auth.onAuthStateChanged((user) => {
  //       if (user) {
  //         resolve(user);
  //       } else {
  //         reject('No user logged in');
  //       }
  //     });
  //   });
  // }

  // updateCurrentUser(value) {
  //   return new Promise<any>((resolve, reject) => {
  //     const user = this.afAuth.currentUser;
  //     user.updateProfile({
  //       displayName: value.name,
  //       photoURL: user.photoURL
  //     }).then(res => {
  //       resolve(res);
  //     }, err => reject(err));
  //   });
  // }


  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }



  registerUserTest(usuario: Usuario) {

    const url = environment.apiUrl + '/' +  environment.apiVersion + '/users/createUserUidFirebase';

    console.log('Registrando Loopback');
    const headers = new HttpHeaders()
    .set('skip', 'true');

    return this.http.post(url, usuario, {headers});

  }


  registerUserFirebase( usuario: Usuario) {
    console.log('Registrando Usuario Firebase');
    return (this.afAuth.createUserWithEmailAndPassword(usuario.email, usuario.password));
  }

  registerUserLoopback( usuario: Usuario ) {
    const url = environment.apiUrl + environment.apiVersion + '/users/';

    // return this.request('POST', [
    //     LoopBackConfig.getPath(),
    //     LoopBackConfig.getApiVersion(),
    //     this.model.getModelDefinition().path
    //   ].join('/'), undefined, undefined, { data }, null, customHeaders)
    //   .pipe(map((data: T) => this.model.factory(data)));
    // }

    console.log('Registrando Loopback');
    const headers = new HttpHeaders()
    .set('skip', 'true');

    return this.http.post(url, usuario, {headers});
    // return this.userApi.create(usuario, customheaders);
  }




  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error);
    });
  }

  // actualizarUsuario( user: User ) {

  //   let url = URL_SERVICIOS + '/usuario/' + user.id;
  //   url += '?token=' + this.token;

  //   return this.http.put( url, usuario )
  //               .map( (resp: any) => {

  //                 if ( user._id === this.usuario._id ) {
  //                   const usuarioDB: Usuario = resp.usuario;
  //                   this.guardarStorage( usuarioDB._id, this.token, usuarioDB, this.menu );
  //                 }

  //                 Swal.fire('Usuario actualizado', usuario.nombre, 'success' );

  //                 return true;
  //               })
  //               .catch( err => {
  //                 Swal.fire( err.error.mensaje, err.error.errors.message, 'error' );
  //                 return throwError( err );
  //               });
  // }

  cambiarImagen( archivo: File, id: string ) {

    this._subirArchivoService.subirArchivo( archivo, 'usuarios', id )
          .then( (resp: any) => {

            this._stateService.userState.usuario.avatar = resp.usuario.avatar;
            Swal.fire( 'Imagen Actualizada', this._stateService.userState.usuario.nombre, 'success' );
            // this.guardarStorage( id, this.token, this.usuario, this.menu );

          })
          .catch( resp => {
            console.log( resp );
          }) ;
  }

  cargarUsuarios( desde: number = 0 ) {
    const url = environment.apiUrl  + environment.apiVersion + '/usuario?desde=' + desde;
    return this.http.get( url );
  }

  buscarUsuarios( termino: string ) {
    const url = environment.apiUrl  + environment.apiVersion + '/busqueda/coleccion/usuarios/' + termino;
    return this.http.get( url )
                .map( (resp: any) => resp.usuarios );
  }


  borrarUsuario( id: string ) {
    let url = environment.apiUrl + environment.apiVersion + '/usuario/' + id;
    url += '?token=' + this.token;
    return this.http.delete( url )
                .map( resp => {
                  Swal.fire('Usuario borrado', 'El usuario a sido eliminado correctamente', 'success');
                  return true;
                });
  }

}
