import { Dashboard } from './../shared/sdk/models/Dashboard';
import { Injectable } from '@angular/core';
import { User as UserFirebase } from 'firebase';
import { User } from '../shared/sdk/models/User';
import { Organization } from '../shared/sdk/models/Organization';
import { OrganizationApi } from '../shared/sdk/services/custom/Organization';
import { UserApi } from 'src/app/shared/sdk/services/custom/User';

import { GenericApi } from '../services/api/api.generic';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';
import { Device } from '../shared/sdk/models/Device';
import { Alert } from 'src/app/shared/sdk/models/Alert';
import { Connector } from '../shared/sdk/models/Connector';
import { AccessToken } from '../shared/sdk/models/AccessToken';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { UsuarioService } from './service.index';

@Injectable({
  providedIn: 'root'
})
export class StateService<TService extends GenericApi> {

  // Handle data of widgets Components
  private widgetData = new BehaviorSubject([]);
  private realtimeDataWidget = new BehaviorSubject([]);
  public getDataWidget$: Observable<Device[]> = this.widgetData.asObservable();
  public realtimeDataWidget$ = this.realtimeDataWidget.asObservable();

  //
  // public user = new User();

  // public userData: IUserState = {
  //   usuario: this.user,
  // };

  // public userState = new UserState(this.userData);


  // public userState: IUserState = {};
  //
  public userState: IUserState ;



  public api: TService;

  public organization: boolean = false;

  // public id: string;



  constructor(private afAuth: AngularFireAuth, public userservice: UsuarioService) {
    this.checkLocalStorage();
  }

  /*
   * If localStoge is empty, we call getDataFromFirebase
   * method set user data from firebase on localStorage
   */
  checkLocalStorage() {
      this.afAuth.authState.subscribe(user => {
        if (user) {
          const userState: IUserState = {};
          this.userState = new UserState(userState);
          const userData: User = {
            ...this.userState.usuario,
            ...user.providerData[0]
          };

          this.userState.usuario = userData; // Seteo el usuario en el servicio
          // no guardo user en localstorage por que ya lo guarda angularfire  por mi.
          // localStorage.setItem('user', JSON.stringify(user));
          // JSON.parse(localStorage.getItem('user'));
        } else {
          // localStorage.setItem('user', null);
          // JSON.parse(localStorage.getItem('user'));

        }
      });



  }



  public isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }



 // *********************************** */

  // SetApi organization or user desde Guard
  setApi(service: TService, id: string) {
    console.log('Seteada api de:', service);
    this.api = service;
    this.api.id = id; // puede ser organizacion o user
    console.log('Sete api con id :', this.api.id);
  }

  // ******Handle data componentes********* */

  public loadData(devices: any) {
    // Init process first load request for widget
    this.widgetData.next(devices);
  }


  public updateOutputWidget(data: any) {

    // Emitir los nuevos valores para que todos los que dependan se actualicen.
    this.realtimeDataWidget.next(data);
  }








}

export interface IUserState {
  token?: AccessToken;
  tokenFirebase?: string;
  usuario?: User;
  user?: UserFirebase;
  loopbackId?: any;
  role?: string;
  username?: string;
  isAuthenticated?: boolean;
  organizationId?: string;
  organization?: Organization | boolean;
  isOwnerOrganization?: boolean;
  api?: any;

}

export class UserState implements IUserState {
  usuario?: User = new User();
  tokenFirebase?: string;
  token?: AccessToken;
  user?: UserFirebase;
  loopbackId?: any;
  role?: string;
  username?: string;
  isAuthenticated?: boolean;
  organizationId?: string;
  organization?: Organization;
  isOwnerOrganization?: boolean;
  api?: any;


  constructor(data?: IUserState) {
    Object.assign(this, data);
  }
}


