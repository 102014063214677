import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import { catchError, map, switchMap, mergeMap, take } from 'rxjs/operators';
import { StateService } from '../state.service';
import { GenericApi } from '../api/api.generic';
import { Organization } from '../../shared/sdk/models/Organization';
import { UserApi } from '../../shared/sdk/services/custom/User';

import { of } from 'rxjs/internal/observable/of';
import { OrganizationApi } from '../../shared/sdk/services/custom/Organization';
import { AngularFireAuth } from '@angular/fire/auth';
import { tap } from 'rxjs/internal/operators/tap';

@Injectable()
export class OrganizationGuard implements CanActivate {

  constructor(private userApi: UserApi,
              private auth: AngularFireAuth,
              private organizationApi: OrganizationApi,
              private router: Router,
              private _stateService: StateService<GenericApi>) {
  }

  /**
   * The function below checks if the user is allowed to view the organization
   * and injects the current organization (if any) in the corresponding service
   */

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkOrganizationMember(route);
    }



    checkOrganizationMember(route: ActivatedRouteSnapshot): Observable<boolean> {
      console.log('**OrganizationGuard');
      console.log(route.params.id);
      const idOrganization = route.params.id;

      // Observable
      const findByIdOrganizations$ = (user: firebase.auth.IdTokenResult) => {
        // if admin
        console.log('claimss', user);
        if( user.claims.role === 'admin') {
          //bypass

          console.log('Seteo Api organization');
          return this.organizationApi.findById(idOrganization)
          .map((organization: Organization) => {
            // Importante -> Seteo api de organization con su id
            console.log('Seteo Api organization');
            this._stateService.setApi(this.organizationApi, idOrganization);
            this._stateService.organization = true;
            this._stateService.userState.organization = organization;
            this._stateService.userState.tokenFirebase = user.token;

            return (user);
          });
        } else {
          return this.userApi.findByIdOrganizations(user.claims.user_id, idOrganization)
          .map((organization: Organization) => {
            // Importante -> Seteo api de organization con su id
            this._stateService.userState.isOwnerOrganization = false;
            if(user.claims.user_id === organization.userId) {
              this._stateService.userState.isOwnerOrganization = true;
            }

            console.log('Seteo Api organization');
            this._stateService.setApi(this.organizationApi, idOrganization);
            this._stateService.organization = true;
            this._stateService.userState.organization = organization;
            this._stateService.userState.tokenFirebase = user.token;

            return (user);
          });
        }
      };

      const update$ = (user: firebase.auth.IdTokenResult) => {
        if( user.claims.role === 'admin') {
          // bypass
          return of(true);
        }

        return this.userApi.updateByIdOrganizations(user.claims.user_id,
        idOrganization, {visitedAt: new Date()}).map((a) => true);
      };

        //

        //  const token = await user.getIdTokenResult();
        // if (!token.claims) { // check claims
        //     this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
        //     return false;

      return this.auth.authState.pipe(
        take(1),
        switchMap((user: firebase.User) => of(user)),
        tap(user => console.log('UserId', user)),
        switchMap((user: firebase.User) => user.getIdTokenResult()),
        switchMap((user: firebase.auth.IdTokenResult) => findByIdOrganizations$(user)), // = map() + mergeAll()
        switchMap((user: firebase.auth.IdTokenResult) => update$(user)), // = map() + mergeAll()
        tap(trues => console.log('GuardUpdateOrganization', trues)),
        catchError((e) => {
          // Not organization member so redirect to overview page
          console.log(e);
          this.router.navigate(['/']);
          return of(false);
        })
      );
      }
}

