import { RouterModule, Routes } from '@angular/router';

import { PagesComponent } from './pages/pages.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './login/register.component';
import { NopagefoundComponent } from './shared/nopagefound/nopagefound.component';

// GUARDS
import { AuthGuard, DashboardGuard, OrganizationGuard, AdminGuard, VerificaTokenGuard, RedirectGuardGuard } from './services/service.index';


import { AccoutSettingsComponent } from './pages/accout-settings/accout-settings.component';

import { BusquedaComponent } from './pages/busqueda/busqueda.component';



const appRoutes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    {
      path: '',
      canActivate: [VerificaTokenGuard, AuthGuard],
      component: PagesComponent,
      data: {
        title: 'Bienvenido'
      },
      children: [
        {
          path: '',
          loadChildren: () => import('./pages/agromonitoring/agromonitoring.module').then(m => m.AgromonitoringModule)
        },
        // { path: 'demo', loadChildren: () => import('./pages/demo/demo.module').then(m => m.DemoModule) },
        {
          path: 'dashboard/:id',
          // canActivate: [DashboardGuard], /**REVISAR */
          canActivate: [DashboardGuard],
          loadChildren:
            () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
        },
        {
          path: 'categories',
          loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule)
        },
        {
          path: 'devices',
          loadChildren: () => import('./pages/devices/devices.module').then(m => m.DevicesModule)
        },
        // {
        //   path: 'devices/:id/tracking',
        //   loadChildren: () => import('./pages/tracking/tracking.module').then(m => m.TrackingModule)
        // },
        {
          path: 'alerts',
          loadChildren: () => import('./pages/alerts/alerts.module').then(m => m.AlertsModule)
        },
        {
          path: 'bd',
          loadChildren: () => import('./pages/bbdd/bd.module').then(m => m.BdModule)
        },
        {
          path: 'messages',
          loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule)
        },
        {
          path: 'messages/:id',
          loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule)
        },
        {
          path: 'api',
          loadChildren: () => import('./pages/api/api.module').then(m => m.ApiModule)
        },
        {
          path: 'parsers',
          loadChildren: () => import('./pages/parsers/parsers.module').then(m => m.ParsersModule)
        },
        {
          path: 'connectors',
          loadChildren: () => import('./pages/connectors/connectors.module').then(m => m.ConnectorsModule)
        },
        {
          path: 'admin',
          canActivate: [AdminGuard],
          loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule)
        },
        // {
        //   path: 'overview',
        //   loadChildren: () => import('./pages/overview/overview.module').then(mod => mod.OverviewModule)
        // },
        {
          path: 'perfil',
          loadChildren: () => import('./pages/profile/profile.module').then( mod => mod.ProfileModule )
        },
        {
          path: 'monitoring',
          loadChildren: () => import('./pages/agromonitoring/agromonitoring.module').then(m => m.AgromonitoringModule)
        },
        {
          path: 'activity',
          loadChildren: () => import('./pages/agroactivity/agroactivity.module').then(m => m.AgroactivityModule)
        },

        { path: 'account-settings', component: AccoutSettingsComponent, data: { titulo: 'Ajustes de Tema' } },

        { path: 'busqueda/:termino', component: BusquedaComponent, data: { titulo: 'Buscador' } },
      ]
    },
    {
      path: 'organization/:id',
      canActivate: [VerificaTokenGuard, OrganizationGuard],
      component: PagesComponent,
      data: {
        title: 'Empresa'
      },
      children: [
        {
          path: '',
          loadChildren: () => import('./pages/agromonitoring/agromonitoring.module').then(m => m.AgromonitoringModule)
        },
        // {
        //   path: 'overview',
        //   loadChildren: () => import('./pages/overview/overview.module').then(m => m.OverviewModule)
        // },

        {
          path: 'dashboard/:id',
          loadChildren:
            () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
        },

        {
          path: 'categories',
          loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule)
        },
        {
          path: 'devices',
          loadChildren: () => import('./pages/devices/devices.module').then(m => m.DevicesModule)
        },
        {
          path: 'connectors',
          loadChildren: () => import('./pages/connectors/connectors.module').then(m => m.ConnectorsModule)
        },
        {
          path: 'alerts',
          loadChildren: () => import('./pages/alerts/alerts.module').then(m => m.AlertsModule)
        },
        // {
        //   path: 'devices/:id/tracking',
        //   loadChildren: () => import('./pages/tracking/tracking.module').then(m => m.TrackingModule)
        // },
        {
          path: 'messages',
          loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule)
        },

        {
          path: 'messages/:id',
          loadChildren: () => import('./pages/messages/messages.module').then(m => m.MessagesModule)
        },
        { path: 'account-organization',
        loadChildren: () => import('./pages/accountSettings/account-organization/account-organization.module')
        .then(m => m.AccountOrganizationModule)},
        {
          path: 'monitoring',
          loadChildren: () => import('./pages/agromonitoring/agromonitoring.module').then(m => m.AgromonitoringModule)
        },
        {
          path: 'activity',
          loadChildren: () => import('./pages/agroactivity/agroactivity.module').then(m => m.AgroactivityModule)
        },
      ]
    },
    { path: '**', component: NopagefoundComponent },
  ];


export const APP_ROUTES = RouterModule.forRoot( appRoutes, { useHash: true } );
