// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  urlCloudFunctionsMonitoring: 'https://satellite-d2i6ccldya-ew.a.run.app/',
  urlCloudFunctions: 'https://europe-west1-agrisensor-280116.cloudfunctions.net',
  //urlCloudFunctionsMonitoring: 'http://localhost:3000/',

  //apiUrl: 'https://agrisensor-backend-prod-d2i6ccldya-ew.a.run.app',

  //urlCloudFunctionsMonitoring: 'http://localhost:5001/agrisensor-280116/europe-west1',
  //urlCloudFunctions: 'http://localhost:5001/agrisensor-280116/europe-west1',
  
  //urlCloudFunctions: 'https://europe-west1-agrisensor-280116.cloudfunctions.net',
  
  apiUrl: 'http://localhost:3000',
  apiVersion: 'api',
  firebaseConfig: {
    apiKey: 'AIzaSyC6VylCjoDgRKBxxLw3LQcne7d44d6oLIs',
    authDomain: 'agrisensor-280116.firebaseapp.com',
    databaseURL: 'https://agrisensor-280116.firebaseio.com',
    projectId: 'agrisensor-280116',
    storageBucket: 'agrisensor-280116.appspot.com',
    messagingSenderId: '858722877150',
    appId: '1:858722877150:web:dd1847cdd1074d1979cc4f',
    measurementId: 'G-SH8FMD0VNP'
  },
};
