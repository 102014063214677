import { Organization } from './../../shared/sdk/models/Organization';
import { User } from './../usuario/user';
import { Injectable } from '@angular/core';
import { UserApi } from '../../shared/sdk/services/custom/User';
import { StateService } from '../state.service';
import { GenericApi } from '../api/api.generic';
import { Router } from '@angular/router';
import { OrganizationApi } from '../../shared/sdk/services/custom/Organization';
import * as _ from 'lodash';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private user: User;
  private organization: Organization;
  private api = `${environment.apiUrl}/${environment.apiVersion}`;
  private organizationCache$: Observable<Organization[]>;

  constructor(private http: HttpClient,private userApi: UserApi, private _stateService: StateService<GenericApi>,
              private router: Router, private organizationApi: OrganizationApi) {

  }

  findByIdOrganizations(userId, idOrganization){
    return this.userApi.findByIdOrganizations( userId, idOrganization);
  }

  findById(organizationId: string){
    return this.organizationApi.findById(organizationId, {include: ['Members', 'user']});
  }

  createOrganization(userId: string, organization: Organization) {
    const organizationMembersAdmin = [{userId}];
    organization.organizationMembersAdmin = organizationMembersAdmin;
    return this.userApi.createOrganizations(userId, organization);
  }

  removeOrganization(userId: string, organization: Organization): any {
    // Usuario no puede tener 0 organizacione
        return this.userApi.destroyByIdOrganizations(userId, organization.id);
  }
  unlinkUserOrganization(userId: string, organization: Organization){
    return this.userApi.unlinkOrganizations(userId, organization.id);

  }


  updateByIdOrganizations(){
    // return this.userApi.updateByIdOrganizations( userId, organization.id, organization);
  }
  countMembers(organizationId){
    return this.organizationApi.countMembers(organizationId);

  }
  // getOrganizations(userId: string) {
  //   return this.userApi.getOrganizations(userId, {order: 'createdAt DESC'})
  //   .map((organizations: Organization[]) => {
  //     // this._stateService.userState.organizations = organizations;
  //     console.log(organizations);
  //     return organizations;
  //     });
  // }

  // getOrganizations(userId: string): Observable<Organization[]> {
  //   if (!this.organizationCache$) {
  //     this.organizationCache$ = this.userApi.getOrganizations(userId, { order: 'createdAt DESC' }).pipe(
  //       shareReplay(1),
  //       catchError((error) => {
  //         console.error('Error fetching organizations', error);
  //         return of([]);
  //       })
  //     );
  //   }
  //   return this.organizationCache$;
  // }


  getOrganizations(userId: string, page?: number, limit?: number): Observable<Organization[]> {
    const skip = page * limit;
    return this.userApi.getOrganizations(userId, {
      order: 'createdAt DESC',
      limit: limit,
      skip: skip
    });
  }

  editOrganization(userId: string,selectedUsers: any, organization: Organization): void {
    const toAdd = _.difference(selectedUsers.map(u => u.id), organization.Members.map(u => u.id));
    const toDel = _.difference(organization.Members.map(u => u.id), selectedUsers.map(u => u.id).concat([userId]));
    toAdd.forEach(user => {
      this.linkMember(organization.id, user).subscribe((result) => {
        console.log('Result after linking member: ', result);
      });
    });
    toDel.forEach(user => {
      this.unlinkMember(organization.id, user).subscribe((result) => {
        console.log('Result after unlinking member: ', result);
      });;
    });
    this.userApi.updateByIdOrganizations( userId, organization.id, organization)
    .subscribe((org: Organization) => {
      console.log('Organization edited', org);
      this.organizationApi.findById(org.id, {include: 'Members'}).subscribe((organization: Organization) => {
        this.organization = organization;
      });
    });
  }

  linkMember(organizationId: string, userId: any) {
    return this.organizationApi.linkMembers(organizationId , userId);
  }

  unlinkMember(organizationId: string, userId: any) {
    return this.organizationApi.unlinkMembers(organizationId , userId);
  }

  asignUserToOrganization(organization: Organization, email: string, organizationMemberAdmin: boolean) {
    // enviar email para invitar a organizacion
    const data = {
      email,
      organizationMemberAdmin
    };

    const url = `${this.api}/OrganizationRequests/${organization.id}/asign-user-to-organization`;
    return this.http.post(url, data);
  }
}


