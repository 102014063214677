import { NgForm } from '@angular/forms';
import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../services/service.index';

import Swal from 'sweetalert2';

declare function init_plugins();
declare const gapi: any;


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  email: string;
  recuerdame = false;

  auth2: any;

  constructor(
    public router: Router,
    public _usuarioService: UsuarioService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
      console.log('login');
  }

  ngOnInit() {
    console.log('onInit Login');

    init_plugins();
    this.googleInit();

    this.email = localStorage.getItem('email') || '';
    if ( this.email.length > 1 ) {
      this.recuerdame = true;
    }

  }

  googleInit() {

    gapi.load('auth2', () => {

      this.auth2 = gapi.auth2.init({
        client_id: '59639180438-q2k8e6m1b0kf6d78sdgb9s4e2fajcea9.apps.googleusercontent.com',
        cookiepolicy: 'single_host_origin',
        scope: 'profile email'
      });

      // this.attachSignin( document.getElementById('btnGoogle') );

    });

  }

  // attachSignin( element ) {

  //   this.auth2.attachClickHandler( element, {}, (googleUser) => {

  //     // let profile = googleUser.getBasicProfile();
  //     let token = googleUser.getAuthResponse().id_token;
  //     let profile = googleUser.getBasicProfile();
  //     console.log(profile);
  //     this._usuarioService.loginGoogle( token )
  //             .subscribe( () => window.location.href = '#/overview'  );

  //   });

  // }

  async resetPassword() {

    const { value} = await Swal.fire({
      title: 'Recuperar contraseña',
      input: 'email',
      inputPlaceholder: 'Introduce tu email',
      showCancelButton: true,
    });
    console.log(value);
    if (value.trim().length > 0) {
      try {
        const res: any = await this._usuarioService.sendPasswordResetEmail(value);
        Swal.fire('Correcto', 'Compruebe su correo.', 'success');
      } catch (err) {
        Swal.fire('Error', 'Este email no está registrado en nuestra plataforma ', 'error');
        console.log(err);
      }
    }

    // this._usuarioService.resetPasswordInit()
    // this.auth.resetPasswordInit(this.email)
    // .then(
    //   () => alert('A password reset link has been sent to your email
    //   address'),
    //   (rejectionReason) => alert(rejectionReason))
    // .catch(e => alert('An error occurred while attempting to reset
    //   your password'));
  }

  ingresar( forma: NgForm) {

    if ( forma.invalid ) {
      return;
    }
    const email = forma.value.email.toLocaleLowerCase();
    const password = forma.value.password;
    console.log(email);
    console.log(password);
    const userIn = {
      email,
      password
    };
    this._usuarioService.loginFirebase(userIn).then(correcto => {
        this.ngZone.run(() => {
          this.router.navigate(['monitoring']);
        });
    }).catch(err => {
      if (err.code === 'auth/user-not-found'){
        Swal.fire('Error', 'Usuario o email no registrado en la aplicacion. Por favor registrese para continuar', 'error');
      } else {
        Swal.fire('Error', 'Error al insertar usuario o password', 'error');
      }


      console.log(err);
    });
    // this._usuarioService.( usuario, forma.value.recuerdame )
    //               .then( correcto => this.router.navigate(['/overview'])  );

    // this.router.navigate([ '/dashboard' ]);

  }

}
