
export class Usuario {

  constructor(
      public email: string,
      public password: string,
      public nombre?: string,
      public img?: string,
      public role?: string,
      public google?: boolean,
      public id?: any,
      public avatar?: string,
      public loopbackId?: any,
      public phoneNumber?: string,
      public displayName?: string,
      public photoURL?: string,
      public disabled?: boolean,
      public emailVerified?: boolean
    ) { }

}


