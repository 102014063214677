<div class="row">

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Usuarios</h4>

                <table class="table table-hover" *ngIf="usuarios.length > 0">
                    <tbody>
                        <tr *ngFor="let usuario of usuarios" [routerLink]="['/usuarios']" class="pointer">
                            <td>
                                <img [src]="usuario.avatar | imagen" class="img-50 img-circle">
                            </td>
                            <td>{{ usuario.nombre }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="alert alert-info" *ngIf="usuarios.length === 0">
                    No hay registros
                </div>

            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Médicos</h4>

                <table class="table table-hover" *ngIf="medicos.length > 0">
                    <tbody>
                        <tr *ngFor="let medico of medicos" [routerLink]="['/medico', medico._id]" class="pointer">
                            <td>
                                <img [src]="medico.img | imagen:'medico'" class="img-50 img-circle">
                            </td>
                            <td>{{ medico.nombre }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="alert alert-info" *ngIf="medicos.length === 0">
                    No hay registros
                </div>

            </div>
        </div>
    </div>

    <div class="col-4">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Hospitales</h4>

                <table class="table table-hover" *ngIf="hospitales.length > 0">
                    <tbody>
                        <tr *ngFor="let hospital of hospitales" [routerLink]="['/hospitales']" class="pointer">
                            <td>
                                <img [src]="hospital.img | imagen:'hospital'" class="img-50 img-circle">
                            </td>
                            <td>{{ hospital.nombre }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="alert alert-info" *ngIf="hospitales.length === 0">
                    No hay registros
                </div>

            </div>
        </div>
    </div>



</div>
