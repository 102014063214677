import { Organization } from './../../shared/sdk/models/Organization';
import { Injectable } from '@angular/core';
import { UsuarioService } from '../usuario/usuario.service';
import { Dashboard } from '../../shared/sdk/models/Dashboard';
import { StateService } from '../state.service';
import { OrganizationApi } from '../../shared/sdk/services/custom/Organization';
import { UserApi } from 'src/app/shared/sdk/services/custom/User';
import { User } from '../../shared/sdk/models/User';
import { getEnabledCategories } from 'trace_events';
import { ParserApi } from '../../shared/sdk/services/custom/Parser';
import { GenericApi } from '../api/api.generic';
import { Usuario } from '../../models/usuario.model';
import { take } from 'rxjs/internal/operators/take';
import { forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';
import { OrganizationService } from '../organization/organization.service';

@Injectable()
export class SidebarService {

  // menu: any[] = [];
  private dashboards: Dashboard[] = [];


  private api;
  private organization: Organization;

  public dataSidebar: any;

  constructor(
    private _usuarioService: UsuarioService,
    private _organizationService: OrganizationService,
    private _stateService: StateService<GenericApi>,
    private organizationApi: OrganizationApi,
    private userApi: UserApi,
    private parserApi: ParserApi,
  ) {

  }

  public menuData$(userId: string) {
     // Get data
    return forkJoin({
      dashboards: this.getDashboards().pipe(catchError(err  => of([err]))),
      numberdashboards: this.getDashboards().pipe(catchError(err  => of([err]))),
      //messages: this.getNumberMessages().pipe(catchError(err  => of([err]))),
      categories: this.getNumberCategories().pipe(catchError(err  => of([err]))),
      devices: this.getNumberDevices().pipe(catchError(err  => of([err]))),
      parsers: this.getParsers().pipe(catchError(err  => of([err]))),
      organizaciones: this._organizationService.getOrganizations(userId).pipe(catchError(err  => of([err]))),
      alerts: this.getAlerts(userId).pipe(catchError(err  => of([err]))),
      beacons: this.getBeacons(userId).pipe(catchError(err  => of([err]))),
      connectors: this.getConnectors(userId).pipe(catchError(err  => of([err]))),
    }).pipe(
      map(data => {
        console.log(data);
        this.dataSidebar = data;
        return data;
      }),
      catchError( err => of(err))
    );
  }

  cargarMenu() {
    // descomentar para menu desde api
    // this.menu = this._usuarioService.menu;
    this.getDashboards();
  }




  getNumberCategories() {
        return this._stateService.api.countCategories(this._stateService.api.id)
        .map(result => {
          return result.count;
        });
      }

  getNumberDevices() {
    return this._stateService.api.countDevices(this._stateService.api.id)
    .map(result => {
      return result.count;
    });
  }

  getNumberMessages() {
    return this._stateService.api.countMessages(this._stateService.api.id)
    .map(result => {
      return result.count;
    });
  }

  getDashboards() {
    return this._stateService.api.getDashboards(this._stateService.api.id, { order: 'createdAt DESC'})
    .map((dashboards: Dashboard[]) => {
      return dashboards;
    });

  }


  //
  getAlerts(userId: string) {
    return this.userApi.countAlerts(userId)
    .map(result => {
      return result.count;
    });
  }

  getParsers() {
    return this.parserApi.count()
    .map(result => {
      return result.count;
    });
  }

  getBeacons(userId: string) {
    return this.userApi.countBeacons(userId)
    .map(result => {
      return result.count;
    });
  }

  getConnectors(userId: string) {
    return this.userApi.countConnectors(userId)
    .map(result => {
      return result.count;
    });
  }




    // subscribe((organizations: Organization[]) => {
    //   this.organizations = organizations;
    //   this.countOrganizationsReady = true;




}
