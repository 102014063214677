import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { from } from 'rxjs/internal/observable/from';
import { AngularFireAuth } from '@angular/fire/auth';
import { take } from 'rxjs/internal/operators/take';
import{ AppConstants} from '../constants/AppConstants';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  // Custom header for passing ID token in request.
  private X_FIREBASE_ID_TOKEN = 'YOUR-CUSTOM-HEADER';
  constructor(private auth: AngularFireAuth) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip register and login -> this.http.get(url, {headers:{skip:"true"});
    if (req.headers.get('skip')) {
      let clone = req.clone();
      delete clone.headers['skip']; // should be lowercase


      return next.handle(clone);

      //return next.handle(req);
    }

   /* Firebase will refresh token automatically if expired */
    return this.auth.idToken.pipe(
    take(1),
    switchMap(idToken => {
        let clone = req.clone();
        if (idToken) {
          // entra
            clone = clone.clone({ headers: req.headers.set('authorization', idToken) });
        }
        return next.handle(clone).pipe(

          catchError( this.handleError)
        );
      })
    );
  }


  handleError( error: any) {
    let errorMessage = '';

    console.log('Sucediendo error');
    console.log('Registrando en el log file error');
    const date = new Date().toUTCString();

    if (error instanceof HttpErrorResponse) {
      //The response body may contain clues as to what went wrong,
      console.error(date, AppConstants.httpError, error.message, 'Status code:',
                                                  (<HttpErrorResponse>error).status);
      errorMessage = `Error: ${error.message}`;
                                       
    }
    else if (error instanceof TypeError) {
      console.error(date, AppConstants.typeError, error.message, error.stack);
      errorMessage = `Error: ${error.message}`;

    }
    else if (error instanceof Error) {
      console.error(date, AppConstants.generalError, error.message, error.stack);
      errorMessage = `Error: ${error.message}`;

    }
    else if(error instanceof ErrorEvent){
      //A client-side or network error occurred. Handle it accordingly.
      console.error(date, AppConstants.generalError, error.message);
      errorMessage = `Error: ${error.message}`;

    }
    else if (error.error instanceof ErrorEvent) {
        // client-side error
        errorMessage = `Error: ${error.error.message}`;
        errorMessage = `Error: ${error.message}`;

    } else {
        // server-side error
        errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
        
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
